<!-- routify:options title="navigation.faq" -->
<script lang="ts">import { _ } from 'svelte-i18n';
import { token } from '../../stores/api.stores';
import { goto } from '@roxi/routify';
import { onMount } from 'svelte';
import { FAQ } from '../../components';
import { warning } from '../../stores/data.stores';
onMount(() => {
    if (!$token) {
        warning.set($_('snackbar.unauthorized'));
        $goto('/', { redirect: 'faq' });
    }
});
</script>

{#if $token}
    <div class="faq-wrapper">
        <span class="bigtitle titleMargin">{$_('faq.title')}</span>
        <FAQ />
    </div>
{/if}

<style lang="stylus">.faq-wrapper {
  display: flex;
  flex-direction: column;
}
.faq-wrapper .titleMargin {
  margin-bottom: 65px;
}</style>
