<script lang="ts">import { onMount } from 'svelte';
import { activeMessage } from './message.stores';
import { attach_icon, unread_icon } from '../../assets';
export let width;
export let displayMessages;
let active = 0;
let messageListElement;
onMount(() => {
    activeMessage.set(displayMessages[0]);
    messageListElement.style.width = width + 'em';
});
function changeActiveMessage(j, msg) {
    active = j;
    activeMessage.set(msg);
}
</script>

<div class="messages" bind:this={messageListElement}>
    <ul class="message-list">
        {#each displayMessages as message, j}
            <li
                class:active-message={active == j}
                on:click={() => changeActiveMessage(j, message)}
                class="message"
                data-messagenum={j}
            >
                <div class="section">
                    {#if message.unread}
                        <img
                            class="unread"
                            src={unread_icon}
                            alt="Unread Icon"
                        />
                    {/if}
                    <span class="sender">
                        {message.sender}
                    </span>
                    <span class="date">
                        {message.date}
                    </span>
                </div>
                <div class="section">
                    <span class="subject">
                        {message.subject}
                    </span>
                    {#if message.attachment}
                        <img src={attach_icon} alt="Attachment Icon" />
                    {/if}
                </div>
            </li>
        {/each}
    </ul>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.message-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  -webkit-padding-start: 0;
  margin-block-start: 0;
}
.message-list .active-message {
  background-color: #fff;
  padding: 1em 2em;
  border-radius: 5px;
  outline: 1px solid var(--color-light-gray);
}
.message-list .message {
  height: 5em;
  padding: 1em 2em;
  margin-bottom: 1em;
  cursor: pointer;
}
.message-list .message .section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.message-list .message .section .unread {
  width: 0.7em;
  height: 0.7em;
  position: absolute;
  margin: 0 0 0 -1.2em;
  align-self: center;
}
.message-list .message .section .sender {
  font-weight: bold;
}
.message-list .message .section .date {
  font-size: 0.8em;
  color: var(--font-color-darker-gray);
  align-self: center;
}
.message-list .message .section .subject {
  width: 92%;
}
.message-list .message .section img {
  height: 1.2em;
  width: 1.1em;
}</style>
