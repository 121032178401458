<script lang="ts">import { attention_yellow, attention_red } from '../../assets';
import Transition from '../transition/Transition.svelte';
import { _ } from 'svelte-i18n';
export let title = '';
export let id = '';
export let boldTitle = true;
export let titleLevel = '2';
export let hint = '';
export let error = '';
export let prependAbove = false;
export let appendBelow = false;
export let fullAppend = false;
export let background = '';
export let padding = '';
export let maxWidth = '';
export let fullBorderRadius = false;
export let borderNone = false;
export let cardList = false;
export let style = '';
export let topBlueBorder = false;
</script>

<div
    class="card"
    class:prependAbove
    class:appendBelow
    class:fullAppend
    class:fullBorderRadius
    class:borderNone
    class:cardList
    class:topBlueBorder
    {style}
>
    <div class="card-content {background} {padding}-padding" style="max-width: {maxWidth}">
        {#if title || $$slots['top-right']}
            <div class="top">
                <span class="title titleLevel-{titleLevel}" class:boldTitle>
                    {title}
                </span>
                <div>
                    <slot name="top-right" />
                </div>
            </div>
        {/if}
        <slot name="bottom" />
    </div>
    {#if hint || error}
        <Transition fadeIn>
            <div class="hint-wrapper" class:hint class:error>
                <img
                    src={error ? attention_red : attention_yellow}
                    alt={error ? 'Red attention' : 'Yellow attention'}
                />
                <div>
                    {hint ? hint : error}
                    <slot name="hint" />
                </div>
            </div>
        </Transition>
    {/if}
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
.landing-padding {
  padding: 24px 29px 20px !important;
}
.request-padding {
  padding: 25px 30px 35px !important;
}
.onboarding-padding {
  padding: 20px 30px 25px !important;
}
.card {
  position: relative;
  width: 100%;
  min-height: 5em;
  box-sizing: border-box;
}
.card .card-content {
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}
.card .card-content.grey {
  background-color: var(--color-gray-background);
}
.card .card-content.white {
  background-color: var(--color-white);
}
.card .card-content.blue {
  background-color: var(--color-primary);
}
.card .card-content.transparent {
  background-color: transparent;
}
.card .card-content .top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card .card-content .top .title {
  flex-grow: 1;
  margin: 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.card .card-content .top .boldTitle {
  font-weight: bold;
}
.card .card-content .top .titleLevel-1 {
  font-size: var(--normal);
}
.card .card-content .top .titleLevel-2 {
  font-size: var(--subheading);
}
.card .card-content .top .titleLevel-3 {
  font-size: var(--titleSmall);
}
.card .card-content .top .titleLevel-4 {
  font-size: var(--small);
}
.card .card-content :global(> div[slot="bottom"]:not(:empty)) {
  margin-top: 1.5em;
}
.card .hint-wrapper {
  position: relative;
  width: 100%;
  padding: 15px 45px;
  box-sizing: border-box;
  font-size: var(--small);
  border-top: 1px solid var(--color-light-gray);
  display: flex;
  flex-direction: row;
}
.card .hint-wrapper img {
  margin: 10px 25px 10px 0;
  align-self: start;
}
.card .hint-wrapper div {
  align-self: center;
}
.card .hint-wrapper.hint {
  background-color: var(--color-light-yellow);
  color: var(--color-black);
  border-radius: 0 0 5px 5px;
}
.card .hint-wrapper.error {
  background-color: var(--color-light-red);
  color: var(--color-danger);
}
.prependAbove {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.appendBelow {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fullAppend {
  border-radius: 0;
}
.fullBorderRadius {
  border-radius: 5px !important;
}
.borderNone {
  border: none;
}
.cardList {
  margin-top: -1px;
}
.topBlueBorder:before {
  content: '';
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: var(--color-primary);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
@media screen and (max-width: 525px) {
  .card .card-content,
  .request-padding {
    padding: 20px 30px;
  }
  .card .hint-wrapper .hint-sections-container .sectionsNames-wrapper {
    margin-left: 10px;
  }
}</style>
