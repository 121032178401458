<script lang="ts">import { arrow_big as arrow } from '@stadtlandnetz/design';
import { _ } from 'svelte-i18n';
import { createEventDispatcher } from 'svelte';
export let hideToday = false;
// Currently not used vvv
export let min = undefined;
export let max = undefined;
export let current = 0;
const dispatch = createEventDispatcher();
</script>

<div class="buttons" class:hideToday>
    <button
        class="card"
        class:active={isNaN(min) || min < current}
        on:click={() => (isNaN(min) || min < current) && dispatch('back')}
    >
        <img src={arrow} alt="back arrow" style="transform: scaleX(-1)" />
    </button>
    <button
        class="card today"
        on:click={() => dispatch('today')}
    >
        {$_('timetable.today')}
    </button>
    <button
        class="card"
        class:active={isNaN(max) || current < max}
        on:click={() => (isNaN(max) || current < max) && dispatch('forward')}
    >
        <img src={arrow} alt="forward arrow" />
    </button>
</div>

<style lang="stylus">.buttons {
  display: grid;
  grid-template-columns: 30px 60px 30px;
}
.buttons.hideToday {
  grid-template-columns: 30px 30px;
}
.buttons.hideToday .today {
  display: none;
}
.buttons .card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 7px 8px;
  min-height: 30px;
  font-size: var(--smaller);
  background: #f9fafb;
  border-radius: 0;
}
.buttons .card:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.buttons .card:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.buttons .card:not(:first-child) {
  border-left: none;
}
.buttons .card.today,
.buttons .card.active {
  background: #fff;
  cursor: pointer;
}
@media screen and (max-width: 940px) {
  .buttons {
    grid-template-columns: 40px 60px 40px;
  }
  .buttons.hideToday {
    grid-template-columns: 40px 40px;
  }
  .buttons .card {
    min-height: 40px;
  }
}</style>