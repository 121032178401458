<script lang="ts">import { createEventDispatcher } from 'svelte';
export let text = '';
export let type = '';
export let level = '2';
export let bold = true;
export let fullWidth = true;
export let style = '';
export let disabled = false;
export let inactive = false;
export let mobileBig = true;
export let mobileAuto = true;
export let aria_label = '';
export let fontSize = null;
export let buttonType = 'button';
const dispatch = createEventDispatcher();
function click() {
    if (!disabled)
        dispatch('click');
}
</script>

<button
    class="{type} size-{level}"
    style={`${style}; font-size: ${fontSize}`}
    {disabled}
    class:full-width={fullWidth}
    class:disabled
    class:inactive
    class:bold
    class:mobile-big={mobileBig}
    class:mobile-auto={!mobileBig && mobileAuto}
    type={buttonType}
    on:click={click}
    aria-label={aria_label}
>
    <slot name="icon" />
    <div class="text">
        <span class="displayed">{@html text}</span>
        <span class="placeholder">{@html text}</span>
    </div>
    <slot class="right" />
</button>

<style src="./button.styl">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
button {
  min-height: 30px;
  padding: 8px 15px;
  min-width: 30px;
  border: solid 1px transparent;
  background-color: var(--color-gray);
  border-radius: 5px;
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}
.text {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
.text .displayed,
.text .placeholder {
  grid-row-start: 1;
  grid-column-start: 1;
}
.text .placeholder {
  font-weight: bold;
  visibility: hidden;
}
button:focus:enabled {
  outline: none;
}
button:hover:enabled {
  transition: all 0.3s ease 0s;
}
.bold {
  font-weight: bold;
}
.full-width {
  width: 100%;
}
.disabled {
  cursor: initial;
  background-color: var(--color-light-gray-fills);
  color: var(--font-color-darker-gray);
  border-color: var(--color-dark-gray);
}
.inactive {
  opacity: 0.4;
}
.primary {
  background-color: var(--color-primary);
}
.primary:hover:enabled {
  background-color: var(--color-primary-hover);
}
.success {
  background-color: var(--color-success);
}
.success:hover:enabled {
  background-color: var(--color-success-hover);
}
.success-inverted {
  border-color: var(--color-success);
  color: var(--color-success);
  background-color: var(--color-white);
}
.danger {
  background-color: var(--color-danger);
}
.danger:hover:enabled {
  background-color: var(--color-danger-hover);
}
.danger-inverted {
  border-color: var(--color-danger);
  color: var(--color-danger);
  background-color: var(--color-white);
}
.bundid {
  background-color: var(--color-dark-blue-bundid);
}
.bundid:hover:enabled {
  background-color: var(--color-dark-blue-bundid-hover);
}
.primary:disabled,
.success:disabled,
.danger:disabled,
.bundid:disabled {
  opacity: 0.3;
  border-color: transparent;
  color: var(--color-white);
}
.outline {
  border-color: var(--color-light-gray);
  background-color: var(--color-white);
  color: var(--color-black);
}
.outline:hover:enabled {
  background-color: var(--color-primary-lightest);
}
.outline:disabled {
  color: var(--font-color-darker-gray);
  background-color: var(--color-light-gray-fills);
}
.data {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.data:hover:enabled {
  background-color: var(--color-primary-lightest);
}
.white-background {
  background-color: var(--color-white);
  margin-left: 25px;
}
.transparent {
  background-color: rgba(0,0,0,0);
  border-color: var(--color-light-gray);
}
.transparent:hover:enabled {
  background-color: rgba(0,0,0,0.1);
}
.link {
  background: none;
  color: var(--color-primary);
  font-weight: 300;
  font-size: var(--smaller);
}
.link:disabled {
  color: var(--color-light-grey);
}
.link {
  color: var(--color-primary-hover);
}
.teal {
  background-color: var(--color-teal);
  color: var(--color-white);
}
.teal:disabled {
  opacity: 0.3;
  color: #fff;
  border-color: transparent;
}
.teal:hover:enabled {
  background-color: var(--color-dark-teal);
}
.size-1 {
  height: 30px;
}
.size-2 {
  height: 40px;
}
.size-3 {
  min-height: 40px;
}
@media screen and (max-width: 940px) {
  .size-1 {
    height: 40px;
  }
}
@media screen and (max-width: 800px) {
  .mobile-auto {
    height: auto;
  }
  .mobile-big {
    height: 60px;
  }
}</style>
