<!-- routify:options title="navigation.account" -->
<script lang="ts">var _a;
import { goto } from '@roxi/routify';
import { Login, getTranslationString } from '@stadtlandnetz/core';
import { AlertBox, Button, GenericCard, account_email, account_password, delete_account, svgLoader } from '@stadtlandnetz/design';
import { InputType } from '@stadtlandnetz/forms';
import { Field as FormField } from '@stadtlandnetz/forms/components';
import { onMount } from 'svelte';
import { _, locale } from 'svelte-i18n';
import { ChangeEmail, ChangePassword, DeleteAccount, ForgotPassword } from '../../components';
import Modal from '../../components/Modal.svelte';
import { Tab } from '../../data';
import { bundID, guardian, infoBoxes, sentTransportRequests } from '../../stores/data.stores';
let account = new Login({});
let changePasswordDialog = false;
let changeEmailDialog = false;
let forgotModal = false;
let deleteAccountDialog = false;
let activeForgotPasswordTab;
let innerWidth = 1111;
function closeModal() {
    changePasswordDialog = false;
    changeEmailDialog = false;
    activeForgotPasswordTab = Tab.Mail;
    forgotModal = false;
    deleteAccountDialog = false;
    if (window.location.hash)
        window.location.hash = '';
}
$: if ($guardian && account) {
    account.mail = $guardian.email;
}
$: if (innerWidth < 1100) {
    if (changePasswordDialog)
        $goto('/mobile/change-password');
    else if (changeEmailDialog)
        $goto('/mobile/change-email');
    else if (forgotModal)
        $goto('/mobile/forgot-password');
    else if (deleteAccountDialog)
        $goto('/mobile/delete-account');
    changePasswordDialog = false;
    changeEmailDialog = false;
    deleteAccountDialog = false;
}
$: userAccountInfoText = getTranslationString((_a = $infoBoxes === null || $infoBoxes === void 0 ? void 0 : $infoBoxes.general) === null || _a === void 0 ? void 0 : _a.userAccountInfo, $locale);
onMount(() => {
    const hash = window.location.hash;
    if (!$bundID) {
        changePasswordDialog = hash === '#changePassword';
        forgotModal = hash === '#forgotPassword';
    }
    changeEmailDialog = hash === '#changeEmail';
    deleteAccountDialog = hash === '#deleteAccount';
});
</script>

<svelte:window bind:innerWidth />

{#if userAccountInfoText}
    <AlertBox type="warning" margin_bottom>
        <span>{userAccountInfoText}</span>
    </AlertBox>
{/if}

<GenericCard title={$_('account.user.title')} appendBelow>
    <div class="accountData" slot="bottom">
        <FormField
            field={{
                name: 'mail',
                id: 'mail',
                type: InputType.TEXT,
                title: 'mail',
                nested: false
            }}
            value={account.mail}
            disabled={true}
            fieldName=""
            classes=""
            inputProps={undefined}
        />
        {#if !$bundID}
            <FormField
                field={{
                    name: 'password',
                    id: 'password',
                    type: InputType.TEXT,
                    title: 'password',
                    nested: false
                }}
                value="●●●●●●●●"
                disabled={true}
                fieldName=""
                classes=""
                inputProps={undefined}
            />
        {/if}
    </div>
</GenericCard>
<div class="card no-top-borders">
    {#if !$bundID}
        <div class="card content-container">
            <img src={account_password} alt="Password Icon" use:svgLoader />
            <div class="sub-container">
                <span>{$_('account.user.change.password')}</span>
                <Button
                    text={$_('account.buttons.update')}
                    type="primary"
                    fullWidth={false}
                    style={innerWidth <= 525 ? 'width: 200px; align-self: center; justify-self: center' : ''}
                    on:click={() => (changePasswordDialog = true)}
                />
            </div>
        </div>
    {/if}

    <div class="card content-container">
        <img src={account_email} alt="Email Icon" use:svgLoader />
        <div class="sub-container">
            <span>{$_('account.user.change.email')}</span>
            <Button
                text={$_('account.buttons.update')}
                type="primary"
                fullWidth={false}
                style={innerWidth <= 525 ? 'width: 200px; align-self: center; justify-self: center' : ''}
                on:click={() => (changeEmailDialog = true)}
            />
        </div>
    </div>

    <div class="card content-container">
        <img src={delete_account} alt="Delete Icon" use:svgLoader />
        <div class="sub-container">
            <span>
                {$_(
                    $sentTransportRequests.length > 0 ? 'account.user.deactivateAccount' : 'account.user.deleteAccount'
                )}
            </span>
            <Button
                text={$_($sentTransportRequests.length > 0 ? 'account.deactivate.button' : 'account.delete.button')}
                type="danger-inverted"
                fullWidth={false}
                on:click={() => (deleteAccountDialog = true)}
            />
        </div>
    </div>
</div>

{#if (changePasswordDialog || changeEmailDialog || forgotModal || deleteAccountDialog) && innerWidth >= 1100}
    <Modal on:close={closeModal}>
        {#if !forgotModal}
            {#if changePasswordDialog}
                <ChangePassword bind:dialog={changePasswordDialog} on:forgot={() => (forgotModal = true)} />
            {:else if changeEmailDialog}
                <ChangeEmail bind:dialog={changeEmailDialog} on:forgot={() => (forgotModal = true)} />
            {:else}
                <DeleteAccount bind:dialog={deleteAccountDialog} on:forgot={() => (forgotModal = true)} />
            {/if}
        {:else}
            <ForgotPassword
                mailFromLogin={account.mail}
                bind:activeTab={activeForgotPasswordTab}
                on:toRegister={() => {
                    closeModal()
                    $goto('/register')
                }}
                on:close={closeModal}
            />
        {/if}
    </Modal>
{/if}

<style lang="stylus">.accountData {
  display: flex;
  column-gap: 40px;
  row-gap: 15px;
}
.accountData :global(.form-group) {
  width: 100%;
}
.card {
  padding: 40px 45px;
  display: grid;
}
.no-top-borders {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.content-container {
  padding: 15px;
  display: grid;
  grid-template-columns: 15% 85%;
  margin-bottom: 20px;
}
.content-container:last-child {
  margin-bottom: 0;
}
.margin {
  margin: 20px 0px;
}
.sub-container {
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  grid-gap: 50px;
  align-items: center;
  justify-content: space-between;
}
.sub-container span {
  color: var(--color-black);
  line-height: 20px;
  font-size: var(--small);
  font-weight: 700;
}
@media screen and (max-width: 1100px) {
  .content-container {
    padding: 10px 20px 10px 0px;
    grid-gap: 5px;
  }
  .sub-container {
    grid-gap: 0px;
    grid-template-columns: 3fr 2fr;
    justify-content: unset;
    padding-left: 10px;
  }
  .sub-container span {
    padding: 10px;
  }
}
@media screen and (max-width: 525px) {
  .accountData {
    flex-direction: column;
  }
  .no-top-borders {
    padding: 0px;
  }
  .content-container {
    border: none;
    grid-template-rows: 30% 70%;
    grid-template-columns: unset;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid var(--color-light-gray);
    border-radius: unset;
    margin-bottom: 0;
  }
  .content-container img {
    align-self: center;
    justify-self: center;
  }
  .content-container:last-child {
    border-bottom: none;
  }
  .sub-container {
    grid-template-columns: unset;
    padding: 0px;
  }
  .sub-container span {
    justify-self: center;
  }
}</style>
