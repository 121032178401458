<script lang="ts">import Button from '../button/Button.svelte';
import { upload_icon, delete_icon, attach_icon } from '../../assets';
import { svgLoader } from '../';
import { createEventDispatcher, onMount } from 'svelte';
import dragDrop from 'drag-drop';
import { _ } from 'svelte-i18n';
import { FileType, MIME_TYPE_MAP } from '../../../core/src';
export let acceptedFiles = [];
export let justUpload = false;
export let existingFiles = [];
export let sizeLimit = 10485760; // 10 Mb in Bytes
export let allowedTypes;
export let disabled;
export let multiple = true;
const dispatch = createEventDispatcher();
let accept = allowedTypes.map((type) => MIME_TYPE_MAP[type]).join(',');
let dropElement;
let inputElement;
let files = [];
let error = false;
let errorText = '';
let errorDescription = [];
function validateFiles() {
    let errors = 0;
    files.forEach((file) => {
        if (allowedTypes.map((type) => MIME_TYPE_MAP[type]).includes(file.type)) {
            if (file.size <= sizeLimit) {
                // convert the file to a Buffer that we can use!
                const reader = new FileReader();
                reader.addEventListener('load', (e) => {
                    file.buffer = e.target.result;
                    // push accepted file to array
                    const fileNames = acceptedFiles.map((file) => file.name);
                    if (!fileNames.includes(file.name)) {
                        if (acceptedFiles.length + existingFiles.length < 100)
                            acceptedFiles = [...acceptedFiles, file];
                        else
                            dispatch('filesLengthExceeded');
                    }
                });
                reader.addEventListener('error', (err) => {
                    console.error('FileReader error' + err);
                });
                reader.readAsArrayBuffer(file);
            }
            else {
                errors++;
                errorDescription = [
                    'upload.errorDescription1',
                    { values: { size: formatBytes(sizeLimit, 0), file: file.name } }
                ];
                console.log('File ', file.name, 'is too large');
            }
        }
        else {
            errors++;
            errorDescription = ['upload.errorDescription2', { values: { file: file.name } }];
            console.log('File ', file.name, 'has no accepted type');
        }
    });
    if (errors > 0) {
        error = true;
        errorText = 'upload.singleError';
        if (errors > 1) {
            errorText = 'upload.multipleError';
            errorDescription = ['upload.errorDescription3', { values: { size: formatBytes(sizeLimit, 0) } }];
        }
    }
    else {
        error = false;
    }
}
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0)
        return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
function removeFile(i) {
    acceptedFiles.splice(i, 1);
    acceptedFiles = [...acceptedFiles];
}
onMount(() => {
    // You can pass in a DOM node or a selector string!
    dragDrop(dropElement, (filesToValidate, pos, fileList, directories) => {
        files = filesToValidate;
        validateFiles();
    });
});
</script>

<div class="card wrapper" class:appendBelow={acceptedFiles.length > 0} bind:this={dropElement}>
    <div class="upper">
        <img src={upload_icon} alt="Upload Icon" use:svgLoader />
        <div class="text">
            {#if error}
                <span class="upper-error">{$_(errorText)}</span>
                <span class="lower-error">{$_(errorDescription[0], errorDescription[1] ?? {})}</span>
            {:else}
                <span class="upper-text">{$_('upload.dropHere')}</span>
                <span class="lower-text">
                    {$_('upload.format', {
                        values: { types: allowedTypes.join(', '), size: formatBytes(sizeLimit, 0) }
                    })}
                </span>
            {/if}
        </div>
    </div>
    <div class="lower">
        <div id="test">
            <Button
                type="primary"
                fullWidth
                text={$_('upload.ownFiles')}
                style="font-size: var(--smaller)"
                on:click={() => inputElement.click()}
                {disabled}
            />
            <input
                type="file"
                {accept}
                bind:this={inputElement}
                on:click={(event) => {
                    // @ts-ignore
                    event.target.value = null
                }}
                on:input={() => {
                    files = Array.from(inputElement.files)
                    validateFiles()
                }}
                {multiple}
            />
        </div>
    </div>
</div>
{#if acceptedFiles.length > 0}
    <ul class="file-list">
        {#each acceptedFiles as file, i}
            <li class="file">
                <div class="left">
                    <img src={attach_icon} alt="Attach Symbol" />
                    <span>{file.name}</span>
                </div>
                <div class="right">
                    <span>{formatBytes(file.size)}</span>
                    <img on:click={() => removeFile(i)} class="delete" src={delete_icon} alt="Delete Symbol" />
                </div>
            </li>
        {/each}
    </ul>
{/if}
{#if existingFiles.length > 0}
    <p class="bold-text">{$_('upload.existing')}:</p>
    <ul class="file-list" style="border-top: 1px solid var(--color-light-gray); border-radius: 5px;">
        {#each existingFiles as file}
            <li class="file">
                <div class="left">
                    <img src={attach_icon} alt="Attach Symbol" />
                    <span>{file.path.split('/')[file.path.split('/').length - 1]}</span>
                </div>
                {#if !disabled}
                    <div class="right">
                        <span />
                        <img
                            on:click={() => dispatch('deleteExistingFile', file.path)}
                            class="delete"
                            src={delete_icon}
                            alt="Delete Symbol"
                        />
                    </div>
                {/if}
            </li>
        {/each}
    </ul>
{/if}

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
.wrapper {
  font-size: 0.9em;
  display: flex;
  padding: 30px;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
}
.appendBelow {
  border-radius: 5px 5px 0 0;
}
.upper {
  display: flex;
  flex-direction: row;
}
.upper img {
  height: 34px;
  align-self: center;
}
.upper .text {
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  line-height: 24px;
  font-size: var(--small);
}
.upper .text .upper-text {
  font-weight: bold;
}
.upper .text .lower-text {
  color: var(--font-color-darker-gray);
}
.lower {
  width: 80%;
  margin-top: 30px;
}
input[type="file"] {
  display: none;
}
.file-list {
  padding: 0;
  margin: 0;
  background-color: var(--color-gray-background);
  list-style-type: none;
  -webkit-padding-start: 0;
  line-height: 41px;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--color-light-gray);
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
}
.file {
  padding: 0 30px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid var(--color-light-gray);
  max-height: 41px;
}
.file img {
  width: 12px;
}
.file div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left {
  overflow: hidden;
  padding-right: 10px;
}
.left img {
  margin-right: 14px;
}
.right {
  justify-content: flex-end;
}
.right span {
  padding-left: 14px;
}
.right img {
  cursor: pointer;
  margin-left: 14px;
}
.file:last-child {
  border-bottom: none;
}
.upper-error {
  color: var(--color-red);
  font-weight: bold;
}
.bold-text {
  font-weight: bold;
}</style>
