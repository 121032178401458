<script lang="ts">import Leaflet from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling' // Do not remove
;
import { onMount, onDestroy } from 'svelte';
import { _ } from 'svelte-i18n';
import { marker_blue, marker_red, marker_green, marker_yellow, marker_gray, marker_blue_noshadow, marker_red_noshadow, marker_green_noshadow, marker_yellow_noshadow, marker_gray_noshadow, marker_bus, marker_tram, marker_walk, marker_taxi, marker_boat, marker_car } from '../../assets';
export let center = [51.115268, 10.812447];
export let zoom = 15;
export let maxZoom = 18;
export let attribution = 'Copyright: StadtLandNetz.de<br>Graphhopper.com, Mapbox.com, Leafletjs.com, OpenStreetMaps.org';
export let rendered = false;
export let leafletMap = null;
export let markerLayer = null;
export let routeLayer = null;
export let polygonLayer = null;
const accessToken = 'pk.eyJ1IjoidmFuZGVybGFycyIsImEiOiJjaWlhZHk4NGEwMDF4dmFsenNqeGtxbHYxIn0.UfUxGINcjLt3HVG2ngTaTA';
const api = `https://api.mapbox.com/styles/v1/vanderlars/ckisppv8f16ah19pfgwtspoah/tiles/256/{z}/{x}/{y}?access_token=${accessToken}`;
// Custom base class
const MarkerIcon = Leaflet.Icon.extend({
    options: {
        iconSize: [35, 35],
        // Offset to set the tip exactly on the coordinates
        iconAnchor: [11, 30],
    },
    // Add index dataset property to icon element
    _setIconStyles: function (img, name) {
        Leaflet.Icon.prototype._setIconStyles.call(this, img, name);
        if (Number.isInteger(this.options.index))
            img.dataset.index = String(this.options.index);
        return img;
    }
});
const TypeIcon = Leaflet.Icon.extend({
    options: {
        iconSize: [24, 24],
        iconAnchor: [13, 13],
    },
});
const markers = {
    red: marker_red,
    green: marker_green,
    blue: marker_blue,
    yellow: marker_yellow,
    gray: marker_gray,
};
const markersNoshadow = {
    red: marker_red_noshadow,
    green: marker_green_noshadow,
    blue: marker_blue_noshadow,
    yellow: marker_yellow_noshadow,
    gray: marker_gray_noshadow,
};
const typeIcons = {
    bus: marker_bus,
    taxi: marker_taxi,
    tram: marker_tram,
    train: marker_tram,
    watercraft: marker_boat,
    walking: marker_walk,
    car: marker_car,
};
const fitBoundsOptions = { paddingTopLeft: [15, 30], paddingBottomRight: [15, 15] };
const resize = () => {
    leafletMap.invalidateSize();
};
const attrObserver = new MutationObserver(mutationsList => {
    mutationsList.forEach(mutation => {
        if (mapHint && mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const target = mutation.target;
            let touch = target.classList.contains('leaflet-gesture-handling-touch-warning');
            let scroll = target.classList.contains('leaflet-gesture-handling-scroll-warning');
            let currentClassState = touch || scroll;
            if (lastClassState !== currentClassState) {
                lastClassState = currentClassState;
                if (currentClassState) {
                    mapHintText = touch ? 'map.hint.touch' : mac ? 'map.hint.scrollMac' : 'map.hint.scroll';
                    mapHint.style.transition = 'opacity 0.3s';
                    mapHint.style.opacity = 1;
                }
                else {
                    mapHint.style.transition = 'opacity 1s';
                    mapHint.style.opacity = 0;
                }
            }
        }
    });
});
let mapContainer, mapHint;
let mapHintText = 'map.hint.scroll';
let lastClassState = false;
let mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
export function getMarkerUrl(color, noshadow = true, letter) {
    let marker = noshadow ? markersNoshadow[color] : markers[color];
    return letter ? marker.replace('%3c!--letter--%3e', letter) : marker;
}
export function getMarker(color, letter, index) {
    return new MarkerIcon({ iconUrl: getMarkerUrl(color, false, letter), index });
}
export function getTypeIconUrl(type) {
    var _a;
    return (_a = typeIcons[type]) !== null && _a !== void 0 ? _a : typeIcons.car;
}
export function getTypeIcon(type) {
    return new TypeIcon({ iconUrl: getTypeIconUrl(type) });
}
export function fitBounds(routes, fly = false) {
    let bounds = getBounds(routes);
    if (fly) {
        leafletMap.flyToBounds(bounds, Object.assign({ duration: 0.35 }, fitBoundsOptions));
    }
    else {
        leafletMap.fitBounds(bounds, fitBoundsOptions);
    }
    resize();
}
function getBounds(routes) {
    const lat = routes.flatMap(route => route.map(coordinate => coordinate[0]));
    const lng = routes.flatMap(route => route.map(coordinate => coordinate[1]));
    return [
        [Math.min(...lat), Math.min(...lng)],
        [Math.max(...lat), Math.max(...lng)]
    ];
}
$: if (mapContainer) {
    attrObserver.disconnect();
    attrObserver.observe(mapContainer, { attributes: true });
}
onMount(() => {
    leafletMap = Leaflet.map(mapContainer, {
        svgSprite: false,
        zoomControl: false,
        center,
        zoom,
        zoomSnap: 0.25,
        gestureHandling: true,
        gestureHandlingOptions: { duration: 500 }
    });
    Leaflet.tileLayer(api, {
        attribution,
        maxZoom,
        useCache: true,
    }).addTo(leafletMap);
    routeLayer = Leaflet.layerGroup().addTo(leafletMap);
    markerLayer = Leaflet.layerGroup().addTo(leafletMap);
    polygonLayer = Leaflet.layerGroup().addTo(leafletMap);
    leafletMap.createPane('routePane');
    leafletMap.createPane('circlePane');
    leafletMap.createPane('markerPane');
    rendered = true;
});
onDestroy(() => rendered = false);
</script>

<svelte:window on:resize="{resize}" />
<div class="map" bind:this="{mapContainer}" on:click>
    <div class="map-hint" bind:this="{mapHint}">{$_(mapHintText)}</div>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.map {
  height: 100%;
  width: 100%;
  position: relative;
}
.map :global(.map-marker-popup > div) {
  border-radius: 5px;
}
.map :global(.map-marker-popup hr) {
  border: 1px solid var(--color-primary);
}
.map-hint {
  color: #fff;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 461;
  pointer-events: none;
  opacity: 0;
}</style>
