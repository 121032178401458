<script lang="ts">var _a, _b, _c, _d, _e, _f;
;
import { EvaluationResult, EvaluationSummary, GenericRoute, displayAsKilometer, getTranslationString } from '@stadtlandnetz/core';
import { _, locale } from 'svelte-i18n';
export let name = '';
export let evaluationText;
export let summary;
export let attendedSchool;
export let closestSchools;
let negativeResultTitle;
let negativeResultText;
let negativeResultNote;
let positiveResultTitle;
let positiveResultText;
let positiveResultNote;
function getNearestSchoolNameAndDistance(schoolEntry) {
    if (schoolEntry.school.name === attendedSchool.name)
        return `<b>${schoolEntry.school.name}: ${displayAsKilometer(schoolEntry.route.overview.totalDistance)}</b>`;
    else if (schoolEntry.route.overview.totalDistance / 1000 <
        parseFloat(summary.minimumDistance.minimumDistance.replace(',', '.')))
        return `${schoolEntry.school.name}: <span style="color:red;">${displayAsKilometer(schoolEntry.route.overview.totalDistance)}</span>`;
    else
        return `${schoolEntry.school.name}: ${displayAsKilometer(schoolEntry.route.overview.totalDistance)}`;
}
$: if ($locale && evaluationText) {
    negativeResultTitle = getTranslationString((_a = evaluationText.title) === null || _a === void 0 ? void 0 : _a[EvaluationResult.NEGATIVE], $locale);
    negativeResultText = getTranslationString((_b = evaluationText.text) === null || _b === void 0 ? void 0 : _b[EvaluationResult.NEGATIVE], $locale);
    negativeResultNote = getTranslationString((_c = evaluationText.note) === null || _c === void 0 ? void 0 : _c[EvaluationResult.NEGATIVE], $locale);
    positiveResultTitle = getTranslationString((_d = evaluationText.title) === null || _d === void 0 ? void 0 : _d[EvaluationResult.POSITIVE], $locale);
    positiveResultText = getTranslationString((_e = evaluationText.text) === null || _e === void 0 ? void 0 : _e[EvaluationResult.POSITIVE], $locale);
    positiveResultNote = getTranslationString((_f = evaluationText.note) === null || _f === void 0 ? void 0 : _f[EvaluationResult.POSITIVE], $locale);
}
</script>

<div class="result" class:success={summary.transportClaim}>
    {#if summary.transportClaim}
        <div class="result-heading successFont">
            {#if positiveResultTitle}
                {@html positiveResultTitle}
            {:else}
                {name}
                {$_('requests.evaluation.accepted-bold')}
            {/if}
        </div>
        {#if positiveResultText}
            <p style="margin: 0">
                {@html positiveResultText}
            </p>
        {/if}
    {:else}
        <div class="result-heading dangerFont">
            {#if negativeResultTitle}
                {@html negativeResultTitle}
            {:else}
                {name}
                {$_('requests.evaluation.declined-bold')}
            {/if}
        </div>
        {#if negativeResultText}
            <p style="margin: 0">
                {@html negativeResultText}
            </p>
        {/if}
    {/if}
    {#if summary.transportClaim && positiveResultNote}
        <p style="margin: 0; margin-top: 20px">
            <span style="font-weight: 700">
                {$_('requests.evaluation.note')}:
            </span>
            {@html positiveResultNote}
        </p>
    {:else if !summary.transportClaim && negativeResultNote}
        <p style="margin: 0; margin-top: 20px">
            <span style="font-weight: 700">
                {$_('requests.evaluation.note')}:
            </span>
            {@html negativeResultNote}
        </p>
    {/if}
</div>

<div class="part">
    <div class="heading">
        {$_('requests.evaluation.address')}
        <!-- TODO: Check address -->
        <div class="badge positive-answer">
            <span>{$_('forms.yes')}</span>
        </div>
    </div>
</div>

<div class="part">
    <div class="heading">
        {$_('requests.evaluation.access.title')}
        {#if summary.transportClaim}
            <div class="badge positive-answer">
                <span>{$_('forms.yes')}</span>
            </div>
        {:else}
            <div class="badge negative-answer">
                <span>{$_('forms.no')}</span>
            </div>
        {/if}
    </div>

    {#if attendedSchool}
        <!--  Check for nearest school  -->
        {#if summary.nearestSchool != null}
            <div class="subpart">
                <div class="sub-headline">
                    {$_('requests.evaluation.access.check1')}
                    {#if summary.nearestSchool.isNearestSchool}
                        <div class="badge positive-answer">
                            <span>{$_('forms.yes')}</span>
                        </div>
                    {:else}
                        <div class="badge negative-answer">
                            <span>{$_('forms.no')}</span>
                        </div>
                    {/if}
                </div>
                {#if !summary.nearestSchool.isNearestSchool && closestSchools?.length > 0}
                    <span class="reason">
                        {$_('requests.evaluation.access.subtext1')}
                        <br />
                        {@html closestSchools
                            .filter((nearestSchool) => nearestSchool.school.name !== attendedSchool.name)
                            .map((nearestSchool) => nearestSchool.school.name)
                            .join('<br> ')}
                    </span>
                {/if}
                {#if !summary.nearestSchool.isNearestSchool && !summary.schoolDistrictCheck && (!closestSchools || closestSchools?.length === 0)}
                    <span class="reason">
                        {$_('requests.evaluation.access.subtext4')}
                    </span>
                {/if}
            </div>
        {/if}

        <!--  Check for minimum distance  -->
        <div class="subpart">
            {#if summary.minimumDistance !== null}
                <div class="sub-headline">
                    {$_('requests.evaluation.access.check2', {
                        values: { minimumDistance: summary.minimumDistance.minimumDistance }
                    })}
                    {#if summary.minimumDistance.minimumDistanceFulfilled}
                        <div class="badge positive-answer level-3">
                            <span>{$_('forms.yes')}</span>
                        </div>
                    {:else}
                        <div class="badge negative-answer level-3">
                            <span>{$_('forms.no')}</span>
                        </div>
                    {/if}
                </div>
                {#if !summary.minimumDistance.minimumDistanceFulfilled && summary.nearestSchool != null && !summary.nearestSchool.isNearestSchool && closestSchools?.length > 0}
                    <span class="reason">
                        {$_('requests.evaluation.access.subtext3')}
                        <br />
                        {@html closestSchools
                            .map((nearestSchool) => getNearestSchoolNameAndDistance(nearestSchool))
                            .join('<br> ')}
                    </span>
                {:else if summary.nearestSchool != null && summary.nearestSchool.isNearestSchool}
                    <span class="reason">
                        {$_('requests.evaluation.access.subtext2', {
                            values: {
                                distance: summary.nearestSchool.distanceToNearestSchool,
                                schoolName: summary.nearestSchool.nearestSchoolName
                            }
                        })}
                    </span>
                {:else}
                    <span class="reason">
                        {$_('requests.evaluation.access.subtext', {
                            values: {
                                distance: summary.minimumDistance.distanceToChosenSchool
                            }
                        })}
                    </span>
                {/if}
            {:else}
                <div class="sub-headline">
                    {$_('requests.evaluation.access.check2-not-applicable')}
                    <div class="badge positive-answer">
                        <span>{$_('forms.yes')}</span>
                    </div>
                </div>
            {/if}
        </div>

        <!--  Check for school district  -->
        {#if summary.schoolDistrictCheck !== null}
            <div class="subpart">
                <div class="sub-headline">
                    {$_('requests.evaluation.access.check4')}
                    {#if summary.schoolDistrictCheck}
                        <div class="badge positive-answer level-3">
                            <span>{$_('forms.yes')}</span>
                        </div>
                    {:else}
                        <div class="badge negative-answer level-3">
                            <span>{$_('forms.no')}</span>
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    {/if}

    <!--  Dangerous or not  -->
    <!--    <div class="subpart">-->
    <!--        <div class="sub-headline">-->
    <!--            {$_('requests.evaluation.access.check3')}-->
    <!--            {#if summary.dangerous}-->
    <!--                <img class="sub-dot" src={greenSubDot}>-->
    <!--            {:else}-->
    <!--                <img class="sub-dot" src={redSubDot}>-->
    <!--            {/if}-->
    <!--        </div>-->
    <!--    </div>-->
</div>

<!--<div class="part">-->
<!--    <div class="heading">-->
<!--        {$_('requests.evaluation.reasonable')}-->
<!--        &lt;!&ndash; TODO: Check reasonable transport &ndash;&gt;-->
<!--        <img class="heading-dot" src={greenDot}>-->
<!--    </div>-->

<!--</div>-->
<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.part {
  background-color: var(--color-gray-background);
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid var(--color-light-gray);
  max-width: 800px;
  overflow: hidden;
}
.part .subpart:last-child {
  border-bottom: none;
}
.heading {
  display: grid;
  grid-template-columns: 1fr 65px;
  padding: 12px 30px;
  font-weight: 700;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.subpart {
  display: flex;
  flex-direction: column;
  padding: 12px 30px;
  background-color: #fff;
  border-bottom: 1px solid var(--color-light-gray);
}
.reason {
  color: var(--font-color-darker-gray);
  display: inline-block;
  margin-top: 8px;
  font-weight: 400;
}
.sub-headline {
  display: grid;
  grid-template-columns: 1fr 65px;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.status {
  display: inline-block;
  border-radius: 15px;
  width: 40px;
  height: 20px;
  font-size: var(--super-small);
  text-align: center;
  line-height: 20px;
  float: right;
  font-weight: 700;
  margin-top: -1px;
}
.result {
  overflow: hidden;
  text-align: center;
  background-color: #fff9f9;
  border-radius: 5px;
  padding: 15px 15px;
  margin-bottom: 30px;
  border: 1px solid var(--color-danger);
}
.result-heading {
  font-weight: bold;
  margin-bottom: 15px;
}
.heading-dot {
  width: 20px;
  height: 20px;
}
.sub-dot {
  width: 12px;
  height: 12px;
  position: relative;
  left: -4px;
}
.success {
  background-color: #f4fffa;
  border: 1px solid var(--color-success);
}
.successFont {
  color: var(--color-success);
}
.dangerFont {
  color: var(--color-danger);
}
.badge {
  justify-self: end;
  border-radius: 15px;
  border: none;
  font-weight: 700;
  text-align: center;
  width: 50px;
  height: 20px;
  padding: 0 3px;
  font-size: var(--smaller);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.positive-answer {
  background-color: var(--color-success);
}
.negative-answer {
  background-color: var(--color-danger);
}</style>
