<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { BankAccount, getTranslationString } from '@stadtlandnetz/core';
;
import { FormGroupStatus } from '@stadtlandnetz/forms';
import { _, locale } from 'svelte-i18n';
import { bankAccountIsEmpty } from '../../helpers';
import { api } from '../../services/api.service';
;
import { loading } from '../../stores/api.stores';
import { bankAccountLegalText, bankAccount as savedBankAccount, warning } from '../../stores/data.stores';
import FormCard from '../Cards/FormCard.svelte';
export let bankAccount;
export let disabled = true;
export let section;
const bankFields = ['iban', 'bic', 'owner', 'bank'];
const initialIban = bankAccount ? bankAccount.iban : '';
const initialOwner = bankAccount ? bankAccount.owner : '';
let formErrors = {};
let alreadyCheckedIban;
let removePlaceholders = false;
function getBankAccountDetails() {
    return __awaiter(this, void 0, void 0, function* () {
        const ibanWasChecked = (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.iban) === alreadyCheckedIban;
        const ownerHasChanged = initialOwner !== (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.owner);
        const onlyIbanIsFilled = (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.iban) !== '' && (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.bic) === '' && (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.bank) === '';
        const validIban = formErrors.iban === undefined;
        const ibanHasChanged = initialIban !== (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.iban);
        const searchBankDetails = validIban && (onlyIbanIsFilled || ibanHasChanged);
        if (ownerHasChanged || ibanWasChecked)
            return;
        if (searchBankDetails) {
            $loading = 'blocking';
            try {
                alreadyCheckedIban = bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.iban;
                const response = yield api.openIbanService.getBankAndBicOutOfIban(bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.iban);
                const bankAccountDetails = (yield response.json());
                if (response.status === 200) {
                    bankAccount.bic =
                        bankAccountDetails.bankData.bic !== undefined ? bankAccountDetails.bankData.bic : '';
                    bankAccount.bank =
                        bankAccountDetails.bankData.name !== undefined ? bankAccountDetails.bankData.name : '';
                }
                else {
                    warning.set($_('snackbar.openIbanError'));
                }
            }
            catch (e) {
                warning.set($_('snackbar.openIbanError'));
                console.error(e);
            }
            finally {
                $loading = undefined;
            }
        }
    });
}
$: if (!disabled && bankAccount && Object.values(bankAccount).every((value) => value === '') && $savedBankAccount) {
    bankAccount = $savedBankAccount;
}
$: if (((_a = section === null || section === void 0 ? void 0 : section.options) === null || _a === void 0 ? void 0 : _a.isOptional) && (bankAccountIsEmpty(bankAccount) || !bankAccount)) {
    //@ts-ignore
    bankAccount = disabled && !bankAccount ? { iban: '', bic: '', owner: '', bank: '' } : bankAccount;
    removePlaceholders = true;
    section.state = FormGroupStatus.Success;
}
</script>

<FormCard
    title={$_('account.bank.title')}
    formClass={BankAccount}
    bind:formObject={bankAccount}
    justInput
    {disabled}
    displayedFields={bankFields}
    {removePlaceholders}
    bind:status={section.state}
    bind:formErrors
    on:change={() => getBankAccountDetails()}
>
    <div class="description" slot="additional-description">
        {@html getTranslationString($bankAccountLegalText, $locale)}
    </div>
</FormCard>

<style lang="stylus">.description :global(:first-child) {
  margin-top: 0px;
}</style>
