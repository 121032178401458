<script lang="ts">export let name = "Bitte ergänzen";
export let address = "Bitte ergänzen";
export let phone = "Bitte ergänzen";
export let mail = "Bitte ergänzen";
</script>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.card {
  height: 203px;
  width: 729px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--color-light-gray);
}
.card-content {
  display: flex;
  flex-direction: column;
  margin-left: 45px;
}
.header {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
button {
  color: var(--color-blue);
  background-color: transparent;
  width: 160px;
  height: 30px;
  font-weight: bold;
  margin-right: 45px;
  border-radius: 6px;
  border: 1px solid var(--color-blue);
}
h1 {
  font-weight: bold;
  margin: 0;
}
small {
  color: var(--font-color-darker-gray);
}
p {
  margin: 0;
}
.content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.line {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}
.attribute {
  color: var(--font-color-darker-gray);
  font-family: var(--font-family);
  width: 100px;
  height: 24px;
  font-size: var(--small);
}
.data {
  color: #000;
  font-family: var(--font-family);
  height: 24px;
}</style>

<div class="card">
    <div class="card-content">
        <div class="header">
            <h1>{name}</h1>
            <button>
                Meine Daten ändern
            </button>
        </div>
        <div class="content">
            <div class="line">
                <p class="attribute">Adresse:</p>
                <p class="data">{address}</p>
            </div>
            <div class="line">
                <p class="attribute">Telefon:</p>
                <p class="data">{phone}</p>
            </div>
            <div class="line">
                <p class="attribute">E-Mail:</p>
                <p class="data">{mail}</p>
            </div>
        </div>
    </div>
</div>
