<script lang="ts">import { createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
;
export let id = '';
export let name = '';
export let classes = '';
export let options = [];
export let disabled = false;
export let value = null;
export let resettable = true;
export let nullOptionTitle = '';
let showNullOption = true;
let disabledBecauseNoOptions = false;
const dispatch = createEventDispatcher();
// Map objects of type System to the system id
if (value !== null &&
    typeof value === 'object' &&
    ['id', 'key', 'value', 'value2', 'value3'].every((key) => Object.keys(value).includes(key))) {
    //@ts-ignore
    value = value.id;
}
$: Array.isArray(options) &&
    options.length &&
    resetValueIfNotPresentInOptions(options);
$: disabledBecauseNoOptions =
    !options || !Array.isArray(options) || options.length === 0;
// If the options given to the Select are changed, we reset the value to null if the currently
// selected value is not available in the options anymore
function resetValueIfNotPresentInOptions(options) {
    if (options.find((option) => option.value === value) === undefined)
        value = null;
}
$: if (value === null) {
    showNullOption = true;
}
else {
    showNullOption = resettable;
}
function onChangeValue(event) {
    if (!disabled)
        dispatch('changeValue', { name, value });
}
$: if (Array.isArray(options) && options.length === 1)
    value = options[0].value;
</script>

<div class="background">
    <select
        {id}
        {name}
        class={classes}
        disabled={disabled || disabledBecauseNoOptions}
        bind:value
        on:change={onChangeValue}
    >
        <!-- <option disabled selected value={null} style="display: none"></option> -->
        {#if Array.isArray(options) && options.length > 0}
            {#if showNullOption}
                <option value={null}>{nullOptionTitle}</option>
            {/if}
            {#each options as option}
                <option value={option.value}>
                    {option.title.startsWith('$')
                        ? $_('forms.select.' + option.title.slice(1))
                        : option.title}
                </option>
            {/each}
        {/if}
    </select>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
.background {
  position: relative;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
}
.background:after {
  content: "";
  width: 1em;
  height: 0.5em;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e%3csvg width='12px' height='6px' viewBox='0 0 12 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3c!-- Generator: Sketch 64 (93537) - https://sketch.com --%3e %3ctitle%3eMisc / Arrow down Copy%3c/title%3e %3cdesc%3eCreated with Sketch.%3c/desc%3e %3cg id='Misc-/-Arrow-down-Copy' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3e %3cpolyline id='Line-Copy-3' stroke='black' stroke-width='2' transform='translate(6.000000%2c 3.000000) scale(-1%2c 1) translate(-6.000000%2c -3.000000) ' points='1 1 6.05076272 5 11 1'%3e%3c/polyline%3e %3c/g%3e%3c/svg%3e");
  grid-area: select;
  justify-self: end;
  margin: 2px 10px 0 0;
}
select {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 1000;
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
  font-size: var(--normal);
  padding: 0 30px 0 10px;
  outline: none;
  appearance: none;
  grid-area: select;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}
select.success {
  border: 1px solid var(--color-success);
}
select.error {
  border: 1px solid var(--color-danger);
}
select:disabled {
  background-color: var(--color-gray-background);
  color: var(--font-color-darker-gray);
  cursor: initial;
}
select::placeholder {
  color: var(--font-color-light-gray);
}
select:focus {
  border: 1px solid var(--color-primary);
  box-shadow: 1px 1px 0 var(--color-primary), -1px -1px 0 var(--color-primary), -1px 1px 0 var(--color-primary), 1px -1px 0 var(--color-primary);
  outline: none;
}
select option {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  select {
    height: 50px;
  }
}</style>
