<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
;
import { Language, PermanentRequestSections, getTranslationObject, getTranslationString } from '@stadtlandnetz/core';
import { GenericCard } from '@stadtlandnetz/design';
import { onMount } from 'svelte';
import { _, locale } from 'svelte-i18n';
import { api } from '../../services/api.service';
import { loading } from '../../stores/api.stores';
import InfoBoxSection from './InfoBoxSection.svelte';
export let allChecked = false;
export let transportRequestType;
let checks;
let consentData;
let localizedContent;
let localizedCheckboxes;
$: if (consentData) {
    const { content, checkboxes } = consentData;
    localizedContent = typeof content === 'string' ? content : getTranslationString(content, $locale);
    localizedCheckboxes =
        (_a = (checkboxes && Language.GERMAN in checkboxes
            ? getTranslationObject(checkboxes, $locale)
            : checkboxes)) !== null && _a !== void 0 ? _a : [];
}
$: if (localizedCheckboxes && !checks)
    checks = new Array(localizedCheckboxes.length).fill(false);
$: allChecked = checks === null || checks === void 0 ? void 0 : checks.every((element) => element === true);
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    window.location.href = '#top';
    $loading = 'blocking';
    consentData = yield api.system.getConsentData();
    $loading = undefined;
}));
</script>

<GenericCard title={$_('requests.confirmation.title')} id="top">
    <div slot="bottom">
        <InfoBoxSection {transportRequestType} section={PermanentRequestSections.DECLARATION_OF_CONSENT} />
        {@html localizedContent ?? ''}
        {#if localizedCheckboxes}
            {#each localizedCheckboxes as checkboxContent, index}
                <div class="row">
                    <input id="checkbox-{index}" bind:checked={checks[index]} type="checkbox" />
                    <label for="checkbox-{index}">{@html checkboxContent}</label>
                </div>
            {/each}
        {/if}
    </div>
</GenericCard>

<style lang="stylus">.iknow {
  display: block;
  margin: 15px 0 5px 0;
}
ul {
  list-style: none;
}
ul li:before {
  content: "\2022";
  color: var(--font-color-darker-gray);
  font-weight: bold;
  display: inline-block;
  width: 2em;
  margin-left: -2em;
}
ul li {
  margin-bottom: 1em;
}
hr {
  width: 100%;
  border: 1px solid var(--color-lighter-gray);
  margin: 30px 0;
}
.header {
  font-size: var(--titleSmall);
  font-weight: bold;
}
.subheading {
  font-weight: bold;
}
.text {
  display: block;
  margin-bottom: 15px;
}
.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: start;
}
.row input {
  margin: 6px 10px 0 5px;
}
.row label {
  cursor: pointer;
}
.row label :global(a) {
  font-weight: bold;
  color: #000;
}</style>
