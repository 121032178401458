<script lang="ts">import CalendarEntry from './CalendarEntry.svelte';
;
export let hour;
export let rowData = [];
export let drawHour = true;
</script>

<tr>
    <td class="time">
        {#if drawHour}
            <span>{('0' + hour).slice(-2)}:00</span>
        {/if}
    </td>

    {#each rowData as day}
        {#if day.length !== 0}
            <td>
                {#each day as entry (entry)}
                    <CalendarEntry
                        {entry}
                        on:showDetail
                    />
                {/each}
            </td>
        {:else}
            <td />
        {/if}
    {/each}
</tr>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
tr td {
  position: relative;
  height: 20px;
  border-bottom: 1px solid var(--color-light-gray);
}
tr td:not(:first-child) {
  border-left: 1px solid var(--color-light-gray);
}
tr td.time {
  color: var(--font-color-darker-gray);
  font-size: var(--smaller);
  position: relative;
  min-width: 30px;
  height: 40px;
  box-sizing: border-box;
}
tr td.time span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-55%);
  width: 65%;
  background: #fff;
  text-align: right;
  padding-right: 10px;
}
tr:last-child td {
  border-bottom: none;
}</style>
