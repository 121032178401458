<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { goto } from '@roxi/routify';
import { ChangeUserDTO } from '@stadtlandnetz/core';
import { Button, arrow_breadcrumbs } from '@stadtlandnetz/design';
import { FormGroupStatus } from '@stadtlandnetz/forms';
import { Field, FormGroup, FormRenderer } from '@stadtlandnetz/forms/components';
import { createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
import { Account } from '../../data';
import { api } from '../../services/api.service';
import { loading } from '../../stores/api.stores';
import { bundID, guardian, success, warning } from '../../stores/data.stores';
import KeyupListener from '../KeyupListener.svelte';
export let dialog = false;
const dispatch = createEventDispatcher();
let account;
let status;
$: if ($guardian && account) {
    account.username = $guardian.email;
}
if ($bundID) {
    $goto('/account');
}
function save() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            $loading = 'blocking';
            const response = yield api.auth.changeUser(new ChangeUserDTO({
                username: account.username,
                newPassword: account.newPassword,
                currentPassword: account.currentPassword
            }));
            if ((response === null || response === void 0 ? void 0 : response.status) === 201) {
                success.set($_('account.user.change.passwordSuccess'));
                dialog = false;
                $goto('/account');
            }
            else if ((response === null || response === void 0 ? void 0 : response.status) === 400) {
                warning.set($_('snackbar.currentPasswordMismatch'));
            }
        }
        finally {
            $loading = undefined;
        }
    });
}
</script>

<KeyupListener key="Enter" condition={status === FormGroupStatus.Success} callback={save} />

<div class="card">
    <div class="content">
        <span class="title">{$_('account.user.change.password')}</span>
        <input type="text" value={account?.username} style="display: none" disabled />
        <FormRenderer DTO={Account} bind:dto={account} bind:status displayGroupName={false} autoRender={false}>
            <FormGroup groupName="Account Credentials" autoRender={false}>
                <div class="field">
                    <Field fieldName="currentPassword" />
                </div>
                <div class="field">
                    <Field fieldName="newPassword" />
                </div>
                <div class="field">
                    <Field fieldName="passwordConfirm" />
                </div>
            </FormGroup>
        </FormRenderer>
    </div>
    <div class="bottom">
        <div class="buttons-container">
            <Button
                text={$_('forms.cancel')}
                type="outline"
                bold={false}
                fullWidth={false}
                on:click={() => {
                    dialog = false
                    $goto('/account')
                }}
            />
            <Button
                type="primary"
                text={$_('account.verification.modal.confirm')}
                fullWidth={false}
                disabled={status !== FormGroupStatus.Success}
                on:click={save}
            />
        </div>
        <div class="forgot-password-link">
            <img src={arrow_breadcrumbs} alt="Arrow Icon" />
            <span on:click={() => dispatch('forgot')}>
                {$_('login.forgot')}
            </span>
        </div>
    </div>
</div>

<style lang="stylus">.card {
  width: 460px;
  overflow: hidden;
}
.card .content {
  padding: 54px 50px 0px 50px;
  display: grid;
}
.card .content .title {
  color: var(--black);
  font-size: var(--titleLarge);
  font-weight: 700;
  margin-bottom: 30px;
}
.card .content .field,
.card .content .text {
  margin-bottom: 20px;
}
.card .content .warning {
  font-weight: 700;
}
.card .bottom {
  background: var(--color-gray-background);
  padding: 20px 50px 30px;
}
.card .bottom .buttons-container {
  display: grid;
  grid-template-columns: 140px 140px;
  align-items: center;
  justify-content: space-between;
}
.card .bottom .forgot-password-link {
  display: flex;
  align-items: center;
  color: var(--font-color-darker-gray);
  text-decoration: underline;
  font-size: var(--smaller);
  cursor: pointer;
  margin-top: 15px;
}
.card .bottom .forgot-password-link span {
  margin-left: 1em;
}
@media screen and (max-width: 1100px) {
  .card {
    width: unset;
  }
  .card .bottom {
    display: grid;
    grid-template-rows: 2.5fr 1fr;
    padding: 30px 45px;
  }
  .card .bottom .buttons-container {
    grid-template-columns: none;
    align-items: unset;
    justify-content: unset;
  }
}
@media screen and (max-width: 800px) {
  .card .bottom .buttons-container {
    grid-gap: 20px;
  }
}</style>
