<script lang="ts">;
import { EvaluationSummary, PermanentRequestSections, TransportRequestState } from '@stadtlandnetz/core';
import { Button, Evaluation, GenericCard, Transition } from '@stadtlandnetz/design';
;
import { FormGroupStatus, SectionName } from '@stadtlandnetz/forms';
import { frontendConfig } from '../../stores/data.stores';
import { createEventDispatcher, onMount } from 'svelte';
import { _ } from 'svelte-i18n';
import { api } from '../../services/api.service';
import InfoBoxSection from './InfoBoxSection.svelte';
export let sections;
export let sectionOrder;
export let transportRequestType;
export let name;
export let attendedSchool;
export let summary = null;
export let complete = false;
export let showButtons = true;
const dispatch = createEventDispatcher();
const getEvaluationTextPromise = api.system.getEvaluationResultText(transportRequestType);
let hint;
let ready = false;
let closestSchools;
function save(selfPay) {
    dispatch('save', { selfPay });
}
$: openSections = Object.entries(sections).filter(([_, section]) => [FormGroupStatus.Empty, FormGroupStatus.Error].includes(section.state));
$: if ((summary === null || summary === void 0 ? void 0 : summary.status) === TransportRequestState.NOT_PRE_EVAUATED &&
    ($frontendConfig === null || $frontendConfig === void 0 ? void 0 : $frontendConfig.enableRequestsWithoutRoutes) &&
    complete) {
    hint = $_('requests.evaluation.notEvaluated');
}
else if (complete && summary) {
    hint = '';
    if (summary.nearestSchool && summary.nearestSchool.nearestSchools.length > 0 && attendedSchool) {
        closestSchools = summary.nearestSchool.nearestSchools.slice(0, summary.nearestSchool.nearestSchools.findIndex((school) => school.school.name === attendedSchool.name) +
            1);
        if (closestSchools.length === 0)
            closestSchools = summary.nearestSchool.nearestSchools;
    }
    ready = true;
}
else {
    hint = $_('requests.evaluation.incomplete');
    ready = false;
}
onMount(() => {
    window.location.href = '#top';
});
</script>

<GenericCard title={$_('requests.evaluation.title')} {hint} id="top">
    <div slot="bottom">
        <InfoBoxSection {transportRequestType} section={PermanentRequestSections.EVALUATION} />
        {#if ready}
            <Transition fadeInOut>
                {#await getEvaluationTextPromise then evaluationText}
                    <Evaluation {name} {evaluationText} {summary} {attendedSchool} {closestSchools} />
                {/await}
                {#if showButtons}
                    <div style="margin-top: 30px" id="submit-buttons">
                        {#if summary && !summary.transportClaim}
                            <Button text={$_('requests.submit-own-risk')} type="data" on:click={() => save(false)} />
                        {:else}
                            <Button text={$_('requests.submit')} type="primary" on:click={() => save(false)} />
                        {/if}
                    </div>
                    {#if summary && !summary.transportClaim}
                        <div style="margin-top: 15px">
                            <Button
                                text={$_('requests.subscriptionTicket')}
                                type="primary"
                                on:click={() => save(true)}
                            />
                        </div>
                    {/if}
                {/if}
            </Transition>
        {/if}
    </div>
    <div slot="hint" class="openSections">
        {#each openSections as [key, section]}
            <span on:click={() => dispatch('loadSection', sectionOrder.indexOf(key))}>
                → {$_(section.name)}
            </span>
        {/each}
    </div>
</GenericCard>

<style lang="stylus" src="./transport-request.styl">.openSections {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.openSections span {
  color: var(--color-black);
  font-weight: bold;
  font-size: var(--small);
  cursor: pointer;
  margin-top: 5px;
}</style>
