<script lang="ts">import { onMount } from 'svelte';
import { _ } from 'svelte-i18n';
export let tabs = ['mail.inbox', 'mail.sent'];
export let activeTabName = tabs[0];
let indicator;
let indicatorLength = 100 / tabs.length;
let sliderElement;
let tabElement;
onMount(() => {
    indicator.style.width = indicatorLength.toString() + '%';
    for (let tab of document.getElementsByClassName('tab')) {
        tab.style.width = indicatorLength.toString() + '%';
    }
});
let activeTab = 0;
function changeTab() {
    activeTab = this.dataset.tabnum;
    activeTabName = tabs[activeTab];
    // Replace indicator
    indicator.style.marginLeft = this.dataset.tabnum * indicatorLength + '%';
}
</script>

<div bind:this={sliderElement}>
    <ul class="tab-bar">
        {#each tabs as tab, i}
            <li
                class:active-tab={activeTab == i}
                on:click={changeTab}
                class="tab"
                data-tabnum={i}
            >
                {$_(tab)}
            </li>
        {/each}
        <div class="indicator" bind:this={indicator} />
    </ul>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
div {
  width: 100%;
}
.tab-bar {
  display: flex;
  list-style-type: none;
  color: var(--font-color-darker-gray);
  -webkit-padding-start: 0;
  padding: 0;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  cursor: pointer;
  font-size: var(--small);
}
.active-tab {
  font-weight: bold;
  color: var(--color-black);
}
.indicator {
  height: 3px;
  background: var(--color-primary);
  position: absolute;
  margin-top: 2em;
  transition: margin 0.5s ease;
  border-radius: 1.5px;
}</style>
