<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button } from '@stadtlandnetz/design';
import { LessonTimes, TransportRequest } from '@stadtlandnetz/core';
;
import { Field as FormField } from '@stadtlandnetz/forms/components';
import { InputType } from '@stadtlandnetz/forms';
import { _ } from 'svelte-i18n';
import { createEventDispatcher, onMount, tick } from 'svelte';
import { format } from 'date-fns';
import { validateSync } from 'class-validator';
export let selectedStudentName;
export let open;
export let requests;
const dispatch = createEventDispatcher();
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
let relevantRequests;
let selectedRequest;
let ready = false;
let edited = false;
let errors = [];
function saveAndClose() {
    return __awaiter(this, void 0, void 0, function* () {
        if (edited)
            dispatch('saveTimes', {
                requests: requests.filter((_, index) => relevantRequests.includes(index))
            });
        yield tick();
        open = false;
    });
}
function selectRequest(index) {
    const request = requests[index];
    requests[index] = !request.lessonTimes ? Object.assign(Object.assign({}, request), { lessonTimes: LessonTimes.getEmptyLessonTimes(request) }) : request;
    selectedRequest = index;
}
function validate() {
    errors = validateSync(requests[selectedRequest].lessonTimes).map(error => error.property);
}
onMount(() => {
    // Only allow editing of requests with public transport
    relevantRequests = requests
        .filter(request => request.hasPublicTransport)
        .map(request => requests.indexOf(request));
    selectRequest(relevantRequests[0]);
    validate();
    ready = true;
});
</script>

{#if open && ready}
    <div class="changeTimes">
        <span class="title">{$_('timetable.changeTimesModal.title')}</span>
        <span class="text">
            {@html $_('timetable.changeTimesModal.text', { values: { name: selectedStudentName } })}
        </span>
        {#if relevantRequests.length > 1}
            <span class="text">{$_('timetable.changeTimesModal.multipleRequests')}</span>
            <div class="select">
                <FormField
                    field={{
                        name: `requestSelect`,
                        id: `requestSelect`,
                        type: InputType.SELECT,
                        options: relevantRequests.map(index => ({
                            title: $_('timetable.changeTimesModal.request', { values: {
                                id: requests[index].id,
                                from: format(requests[index].valid.since, 'dd.MM.yyyy'),
                                until: format(requests[index].valid.until, 'dd.MM.yyyy')
                            } }),
                            value: index
                        }))
                    }}
                    value={selectedRequest}
                    on:change={event => selectRequest(event.detail.value)}
                    disabled={false}
                    fieldName=""
                    classes=""
                    inputProps={{ resettable: false }}
                />
            </div>
        {/if}
        <div class="times">
            {#each days as day}
                <div class="row">
                    <span class="day">{$_(`misc.days.${day}`)}</span>
                    <span>{$_('timetable.from')}</span>
                    <div class="time">
                        <FormField
                            field={{
                                name: `${day}Start`,
                                id: `${day}Start`,
                                type: InputType.TIME
                            }}
                            bind:value={requests[selectedRequest].lessonTimes[day].start}
                            disabled={false}
                            fieldName=""
                            classes={errors.includes(day) ? 'error' : ''}
                            inputProps={undefined}
                            on:change|once={() => edited = true}
                            on:inputBlur={validate}
                        />
                    </div>
                    <span>{$_('timetable.until')}</span>
                    <div class="time">
                        <FormField
                            field={{
                                name: `${day}End`,
                                id: `${day}End`,
                                type: InputType.TIME
                            }}
                            bind:value={requests[selectedRequest].lessonTimes[day].end}
                            disabled={false}
                            fieldName=""
                            classes={errors.includes(day) ? 'error' : ''}
                            inputProps={undefined}
                            on:change|once={() => edited = true}
                            on:inputBlur={validate}
                        />
                    </div>
                </div>
                {#if errors.includes(day)}
                    <span class="error">{$_('forms.error-messages.startBeforeEnd')}</span>
                {/if}
            {/each}
        </div>
    </div>
    <div class="bottom">
        <Button
            text={$_('forms.cancel')}
            type="outline"
            bold={false}
            fullWidth={false}
            on:click={() => (open = false)}
        />
        <Button
            text={$_('account.buttons.save')}
            type="primary"
            fullWidth={false}
            disabled={
                errors.length !== 0 ||
                days.every(
                    (day) =>
                        !requests[selectedRequest].lessonTimes[day].start ||
                        !requests[selectedRequest].lessonTimes[day].end
                )
            }
            on:click={saveAndClose}
        />
    </div>
{/if}

<style lang="stylus">.changeTimes {
  display: flex;
  flex-direction: column;
  padding: 54px 50px 30px;
  max-width: 600px;
  box-sizing: border-box;
}
.changeTimes .title {
  font-size: var(--titleLarge);
  font-weight: bold;
  margin-bottom: 30px;
}
.changeTimes .text {
  font-size: var(--normal);
  margin-bottom: 15px;
}
.changeTimes .select {
  margin: 10px 0;
}
.changeTimes .times {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.changeTimes .times .row {
  display: flex;
  align-items: center;
  width: 100%;
}
.changeTimes .times .row:not(:first-child) {
  margin-top: 10px;
}
.changeTimes .times .row *:not(.day) {
  margin-left: 30px;
}
.changeTimes .times .row .day {
  font-weight: bold;
  margin-right: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}
.changeTimes .times .row .time {
  min-width: 96px;
}
.changeTimes .times .error {
  color: var(--color-danger);
  font-size: var(--smaller);
  align-self: end;
}
.bottom {
  padding: 20px 50px 30px;
  display: grid;
  grid-template-columns: 140px 140px;
  justify-content: space-between;
  background: var(--color-gray-background);
}
@media screen and (max-width: 700px) {
  .changeTimes {
    padding: 54px 30px 30px;
  }
  .bottom {
    padding: 20px 30px 30px;
  }
}
@media screen and (max-width: 600px) {
  .changeTimes {
    padding: 25px 15px 30px;
  }
  .changeTimes .title {
    font-size: var(--subheading);
  }
  .changeTimes .text {
    margin-bottom: 10px;
  }
  .changeTimes .times {
    margin-top: 10px;
  }
  .changeTimes .times .row *:not(.day) {
    margin-left: 10px;
  }
  .bottom {
    padding: 0 15px 30px;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    background: none;
  }
}</style>
