<script lang="ts">import { clickOutside } from "../helpers";
import { _ } from 'svelte-i18n';
import { createEventDispatcher } from "svelte";
export let title = '';
export let showDropdown = false;
export let values;
export let displayedText = null;
const dispatch = createEventDispatcher();
const showIcon = !!$$slots.icon;
let selected = Object.keys(values)[0];
</script>

<div
    class="wrapper"
    class:withTitle={title}
    use:clickOutside
    on:outclick={() => (showDropdown = false)}
>
    {#if title}
        <span class="title">{title}</span>
    {/if}
    <div
        class="card selection"
        class:active={showDropdown}
        class:showIcon
        title={values[selected]}
        on:click={() => (showDropdown = !showDropdown)}
    >
        {#if showIcon}
            <div class="icon"><slot name="icon" /></div>
        {/if}
        <span>{displayedText ?? values[selected]}</span>
    </div>
    {#if showDropdown}
        <div class="dropdown">
            {#each Object.entries(values) as [key, value]}
                <div
                    class="card"
                    title={value}
                    on:click={() => {
                        selected = key
                        dispatch('select', { key })
                        showDropdown = false
                    }}
                >
                    <span>{value}</span>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style lang="stylus">.wrapper {
  position: relative;
  max-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--small);
}
.wrapper.withTitle {
  max-height: 84px;
}
.wrapper .title {
  color: var(--font-color-darker-gray);
}
.wrapper .title:after {
  content: ':';
}
.wrapper .card {
  width: 100%;
  min-height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--normal);
  white-space: nowrap;
}
.wrapper .card span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper .selection {
  position: relative;
  font-weight: bold;
  transition: all 0.2s ease;
  box-sizing: border-box;
  padding-right: 30px;
  gap: 20px;
}
.wrapper .selection:after {
  content: "";
  position: absolute;
  right: 15px;
  width: 12px;
  height: 0.5em;
  background: no-repeat url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e%3csvg width='12px' height='6px' viewBox='0 0 12 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3c!-- Generator: Sketch 64 (93537) - https://sketch.com --%3e %3ctitle%3eMisc / Arrow down Copy%3c/title%3e %3cdesc%3eCreated with Sketch.%3c/desc%3e %3cg id='Misc-/-Arrow-down-Copy' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3e %3cpolyline id='Line-Copy-3' stroke='black' stroke-width='2' transform='translate(6.000000%2c 3.000000) scale(-1%2c 1) translate(-6.000000%2c -3.000000) ' points='1 1 6.05076272 5 11 1'%3e%3c/polyline%3e %3c/g%3e%3c/svg%3e");
  margin-top: 2px;
  transition: transform 0.3s ease;
}
.wrapper .selection.active {
  border-color: var(--color-primary);
  filter: drop-shadow(0 0 4px var(--font-color-footer-stamp));
}
.wrapper .selection.active:after {
  transform: rotate(180deg) translateY(3px);
}
.wrapper .selection.showIcon {
  font-size: var(--small);
  padding-left: 0;
}
.wrapper .selection .icon {
  background: var(--color-primary);
  display: flex;
  align-self: stretch;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.wrapper .dropdown {
  margin-top: 10px;
  width: inherit;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: solid 1px var(--color-light-gray);
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.1);
  box-sizing: border-box;
  z-index: 10;
}
.wrapper .dropdown .card {
  border: none;
  background: none;
  border-radius: 0;
  transition: all 0.15s ease;
  box-sizing: border-box;
}
.wrapper .dropdown .card:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.wrapper .dropdown .card:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.wrapper .dropdown .card:not(:last-child) {
  border-bottom: inherit;
}
.wrapper .dropdown .card:hover {
  box-shadow: inset 0 0 9999px var(--color-primary-lightest);
}
@media screen and (max-width: 800px) {
  .wrapper.withTitle {
    max-height: 63px;
  }
  .wrapper.withTitle .title {
    color: #000;
    font-size: 28px;
    font-weight: bold;
  }
  .wrapper.withTitle .title:after {
    content: '';
  }
  .wrapper.withTitle .selection {
    background: none;
    border: none;
    font-weight: normal;
    padding-left: 0;
    color: var(--font-color-darker-gray);
    min-height: fit-content;
    filter: none !important;
    padding-top: 15px;
  }
  .wrapper.withTitle .selection:after {
    top: 2px;
  }
  .wrapper.withTitle .dropdown {
    width: 320px;
    margin-left: auto;
    margin-top: 6px;
  }
}</style>