<script lang="ts">;
import { Direction } from '@stadtlandnetz/core';
import { addDays, format, getISOWeek, isSameHour, setHours, subDays } from 'date-fns';
import { Button, tour_outward, tour_return } from '@stadtlandnetz/design';
import CalendarRow from './CalendarRow.svelte';
import Navigation from './Navigation.svelte';
import { createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
import { getSortedJourneys } from '../../helpers';
export let locale;
export let studentName = '';
export let noLessonTimes = false;
export let noJourneysFor = [];
export let hasPublicTransport = false;
export let startHour = 6;
export let endHour = 18;
export let dateRange;
export let timetable;
export let mobile = false;
const dispatch = createEventDispatcher();
const today = new Date();
let rowEntries;
$: if (timetable)
    calculateRowEntries();
$: inactive = noLessonTimes || noJourneysFor.length;
// Calculate entries that should be shown
function calculateRowEntries() {
    var _a, _b;
    const rows = {};
    for (let hour = startHour; hour <= endHour; hour++) {
        const row = [];
        for (const day of dateRange) {
            const cellTime = setHours(day, hour);
            const weekday = format(day, 'EEEE').toLowerCase();
            row.push((_b = (_a = timetable[weekday]) === null || _a === void 0 ? void 0 : _a.filter((entry) => {
                return isSameHour(entry.begin, cellTime);
            })) !== null && _b !== void 0 ? _b : []);
        }
        rows[hour] = row;
    }
    rowEntries = rows;
}
</script>

{#if dateRange}
    <div class="navigation" class:inactive>
        <Navigation
            on:back={() => dispatch('changeWeek', subDays(dateRange[0], 7))}
            on:today={() => dispatch('changeWeek', new Date())}
            on:forward={() => dispatch('changeWeek', addDays(dateRange[0], 7))}
        />
        <span>
            {!mobile ? $_('timetable.calendar-week', { values: { cw: getISOWeek(dateRange[0]) } }) : ''}
            {format(dateRange[0], !mobile ? '(dd. MMMM' : 'dd.', { locale })}
            {$_('timetable.to')}
            {format(dateRange[6], 'dd. MMMM yyyy', { locale })}{!mobile ? ')' : ''}
        </span>
        {#if hasPublicTransport && !noLessonTimes && !mobile}
            <div class="changeTimes">
                <Button
                    text={$_('timetable.changeTimes')}
                    type="data"
                    level=1
                    fullWidth={false}
                    style="font-size: var(--smaller)"
                    on:click={() => dispatch('changeTimes')}
                />
            </div>
        {/if}
    </div>
    <div class="calendar">
        {#if noLessonTimes || noJourneysFor.length}
            <div class="modal card">
                <span class="title">
                    {$_(`timetable.${noLessonTimes ? 'noTimesModal' : 'noJourneysForModal'}.title`)}
                </span>
                <span class="text">
                    {@html $_(`timetable.${noLessonTimes ? 'noTimesModal' : 'noJourneysForModal'}.text`, {
                        values: noLessonTimes ? {
                            name: studentName
                        } : {
                            days: noJourneysFor
                                .map(day => $_(`misc.days.${day}`))
                                .reduce((prev, val, i, arr) =>
                                    prev + (i === arr.length - 1 ? ` ${$_('misc.and')}` : ',') + ` ${val}`
                                ),
                            num: noJourneysFor.length
                        }
                    })}
                </span>
                <Button
                    text={$_(`timetable.${noLessonTimes ? 'noTimesModal' : 'noJourneysForModal'}.button`)}
                    type="primary"
                    fullWidth={false}
                    mobileBig={false}
                    mobileAuto={false}
                    on:click={() => noLessonTimes ? dispatch('changeTimes') : noJourneysFor = []}
                />
            </div>
        {/if}
        {#if !mobile}
            <table class="card">
                <thead>
                    <tr>
                        <td />
                        {#each dateRange as day}
                            <td class:today={format(day, 'dd.MM.yyyy') === format(today, 'dd.MM.yyyy')}>
                                <h3>{format(day, 'EEEE', { locale })}</h3>
                                <small>{format(day, 'dd.MM.yyyy')}</small>
                            </td>
                        {/each}
                    </tr>
                </thead>
                {#if rowEntries}
                    <tbody>
                        {#each Object.entries(rowEntries) as [hour, entry] (hour)}
                            <CalendarRow
                                hour={Number(hour)}
                                rowData={entry}
                                drawHour={Number(hour) !== startHour}
                                on:showDetail
                            />
                        {/each}
                    </tbody>
                {/if}
            </table>
        {:else}
            <div class="mobileRows" class:inactive>
                {#each
                    dateRange
                        .slice(0, -2)
                        .map(day => timetable ? ({
                            day,
                            entries: getSortedJourneys(timetable[format(day, 'EEEE').toLowerCase()])
                        }) : { day, entries: [] })
                    as { day, entries }
                }
                    <div class="row card">
                        <div class="date">
                            <span class="dayName">{format(day, 'EEEEEE')}</span>
                            <span
                                class="dayNumber"
                                class:today={format(day, 'dd.MM.yyyy') === format(today, 'dd.MM.yyyy')}
                            >
                                {day.getDate()}
                            </span>
                        </div>
                        <div class="journeys" on:click={() => entries.length && dispatch('showDetail', entries[0])}>
                            {#each entries as entry}
                                <div class="journey">
                                    <img
                                        src={entry.direction === Direction.TO ? tour_outward : tour_return}
                                        alt="return"
                                    />
                                    <span class="type">
                                        {$_('timetable' + (entry.direction === Direction.TO ? '.outward' : '.return'))}
                                    </span>
                                    <span class="time">{$_('timetable.at')} {format(entry.begin, 'HH:mm')}</span>
                                </div>
                            {/each}
                        </div>
                    </div>
                {/each}
            </div>
            {#if hasPublicTransport && !noLessonTimes}
                <div class="changeTimes" class:inactive>
                    <Button
                        text={$_('timetable.changeLessonTimes')}
                        type="primary"
                        fullWidth={false}
                        mobileBig={false}
                        mobileAuto={false}
                        style="padding-left: 20px; padding-right: 20px"
                        on:click={() => dispatch('changeTimes')}
                    />
                </div>
            {/if}
        {/if}
    </div>
{/if}

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.navigation {
  display: flex;
  align-items: center;
  color: var(--color-black);
  margin-bottom: 18px;
  overflow: hidden;
}
.navigation span {
  margin-left: 20px;
  font-weight: bold;
  font-size: var(--subheading);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navigation .changeTimes {
  margin-left: auto;
}
.calendar {
  position: relative;
  overflow-y: auto;
}
.calendar .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 420px;
  padding: 30px 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 5px rgba(0,0,0,0.1);
  box-sizing: border-box;
  z-index: 10;
}
.calendar .modal .title {
  font-size: var(--normal);
  font-weight: bold;
}
.calendar .modal span {
  margin-bottom: 25px;
}
.calendar table {
  border-spacing: 0;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  table-layout: fixed;
}
.calendar table thead td {
  border-bottom: 1px solid var(--color-light-gray);
}
.calendar table thead td:first-child {
  width: 95px;
}
.calendar table thead td.today > * {
  color: var(--color-primary);
}
.calendar table thead td h3 {
  margin: 0;
  color: var(--color-black);
  font-size: var(--small);
  font-weight: bold;
  text-align: center;
}
.calendar table thead td small {
  display: block;
  color: var(--font-color-darker-gray);
  font-size: var(--smaller);
  text-align: center;
}
.calendar .mobileRows {
  width: 100%;
}
.calendar .mobileRows .row {
  display: grid;
  grid-template-columns: 45px auto;
  min-height: 80px;
  background: #fff;
  box-sizing: border-box;
}
.calendar .mobileRows .row:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.calendar .mobileRows .row:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.calendar .mobileRows .row .date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2px;
  background: var(--color-gray-background);
  border-right: solid 1px var(--color-light-gray);
}
.calendar .mobileRows .row .date .dayName {
  font-size: var(--super-small);
  color: var(--font-color-darker-gray);
  line-height: 12px;
}
.calendar .mobileRows .row .date .dayNumber {
  font-size: var(--small);
  font-weight: bold;
  line-height: 19px;
}
.calendar .mobileRows .row .date .dayNumber.today {
  background: #000;
  border-radius: 10px;
  color: #fff;
  width: 26px;
}
.calendar .mobileRows .row .journeys {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.calendar .mobileRows .row .journeys .journey {
  height: 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.calendar .mobileRows .row .journeys .journey img {
  margin-right: 5px;
  width: 20px;
}
.calendar .mobileRows .row .journeys .journey .type {
  font-weight: bold;
}
.calendar .mobileRows .row .journeys .journey .time {
  color: var(--font-color-darker-gray);
  margin-left: auto;
}
@media screen and (max-width: 800px) {
  .navigation {
    margin-bottom: 10px;
  }
  .navigation span {
    font-size: var(--small);
    margin-left: 15px;
  }
  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .calendar .modal {
    width: 270px;
    padding: 30px 25px 25px;
  }
  .calendar .changeTimes {
    margin-top: 30px;
  }
  .inactive {
    opacity: 0.5;
  }
}</style>
