<script lang="ts">import { createEventDispatcher, setContext } from 'svelte';
import { buildForm } from '../builder';
;
;
import { FormGroupStatus } from '../types';
import { validateForm } from '../validation';
import FormGroup from './FormGroup.svelte';
import { formErrors } from './stores/error.stores';
export let fields = null;
export let DTO = null;
export let disabled = false;
export let dto = new DTO({});
export let status;
export let options = [];
export let displayedFields = null;
export let displayGroupName = true;
export let backgroundColor = '';
export let autoRender = true;
export let removeOneLiner = false;
export let errors = {};
export let classesForFields = '';
export let removePlaceholders = false;
export let validationGroups = null;
const optionUpdates = {};
const dispatch = createEventDispatcher();
let form, relevantError;
if (!fields)
    form = buildForm(DTO, displayedFields);
$: if (displayedFields) {
    form = buildForm(DTO, displayedFields);
}
for (const group of form.groups) {
    setContext(group.name, {
        getGroup: () => group,
        setFields: (groupFields) => addFieldsForGroup(groupFields),
        getData: () => (group.nested ? dto[group.nested] : dto),
        setData: (data) => {
            if (group.nested)
                dto[group.nested] = data;
            else
                dto = data;
        },
        getErrors: () => errors,
        setRelevantError: (data) => (relevantError = data)
    });
}
function addFieldsForGroup(fieldsForGroup) {
    if (!fieldsForGroup && fieldsForGroup.length === 0)
        return;
    if (!displayedFields)
        displayedFields = [];
    displayedFields = [...new Set(displayedFields.concat(fieldsForGroup))];
}
// Method to inject options to fields
$: options.forEach((option) => {
    const fields = form.fields;
    const field = fields.find((field) => option.propertyName === field.name || option.propertyName === field.title);
    if (field) {
        if (Array.isArray(option.dynamicValues))
            field.options = option.dynamicValues;
        if (typeof option.dynamicValues === 'function')
            optionUpdates[field.name] = {
                runOn: option.runOn,
                function: option.dynamicValues
            };
        if (typeof option.readonly === 'function')
            field.readonly = option.readonly(dto);
        if (option.options) {
            for (const [key, value] of Object.entries(option.options)) {
                field[key] = typeof value === 'function' ? value(dto) : value;
            }
        }
    }
    form.fields = fields;
});
$: errors = validateForm(form, new DTO(Object.assign({}, dto)), validationGroups);
$: status = relevantError
    ? FormGroupStatus.Error
    : Object.values(errors).every((error) => !error)
        ? FormGroupStatus.Success
        : FormGroupStatus.Empty;
$: if (errors && !autoRender) {
    $formErrors = errors;
}
// TODO: Implement this also when using ContextAPI
function onChange(event) {
    // TODO: Find a better way. Maybe everything via events to effectively create a custom two-way binding mechanism?
    // The timeout seems to be necessary to make sure that the two-way binding has propagated to the local `dto`
    // object. Otherwise, it can happen that the functions responsible for calculating the new options are invoked
    // with outdated values.
    setTimeout(() => {
        for (const [fieldName, update] of Object.entries(optionUpdates)) {
            if (Array.isArray(update.runOn) && !update.runOn.includes(event.detail.name))
                continue;
            Promise.resolve(update.function(dto)).then((newOptions) => {
                const field = form.fields.find((field) => field.name == fieldName);
                field.options = newOptions;
                const groupIndex = form.groups.findIndex((group) => group.fields.find((field) => field.name == fieldName));
                form.groups[groupIndex].fields = form.groups[groupIndex].fields;
            });
        }
        dispatch('change', event);
    }, 500);
}
</script>

{#if autoRender}
    <div class="form two-columns-layout">
        {#each form.groups.filter((group) => group.fields.some((field) => !field.hidden)) as group (group.name)}
            <div class="group">
                {#if group.nested}
                    <FormGroup
                        bind:group
                        bind:data={dto[group.nested]}
                        bind:disabled
                        {errors}
                        bind:relevantError
                        bind:displayGroupName
                        bind:removeOneLiner
                        {backgroundColor}
                        {classesForFields}
                        {removePlaceholders}
                        on:change={onChange}
                    />
                {:else}
                    <FormGroup
                        bind:group
                        bind:data={dto}
                        bind:disabled
                        {errors}
                        bind:relevantError
                        bind:displayGroupName
                        bind:removeOneLiner
                        {backgroundColor}
                        {classesForFields}
                        {removePlaceholders}
                        on:change={onChange}
                    />
                {/if}
            </div>
        {/each}
    </div>
{:else}
    <slot />
{/if}

<style lang="stylus">.two-columns {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
}
.group {
  margin-bottom: 30px;
}
.group:last-child {
  margin-bottom: 0;
}
.group:last-child .two-columns-layout h4 {
  grid-column: 1/2 span;
}
.group:last-child .col-2 {
  grid-column: 1/2 span;
}</style>
