<script lang="ts">import { _ } from 'svelte-i18n';
export let path = [];
export let footerLink;
</script>

<ul>
    {#each path as part}
        <li>
            <a href={part.url !== '/:footerLink' ? part.url : footerLink}>
                {$_(part.url !== '/:footerLink' ? part.name : footerLink)}
            </a>
        </li>
    {/each}
</ul>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
ul {
  list-style: none;
  padding-left: 0;
}
ul li {
  margin-block-start: 0;
  display: inline;
  font-size: var(--small);
  font-family: var(--font-family);
}
ul li+li::before {
  margin-left: -3px;
  padding: 11px;
  color: var(--font-color-darker-gray);
  content: "❯";
}
a {
  text-decoration: none;
  color: var(--font-color-darker-gray);
}
li:last-child a {
  font-weight: bold;
}
@media screen and (max-width: 525px) {
  a {
    font-size: var(--smaller);
  }
}</style>
