<script lang="ts">import { goto } from '@roxi/routify';
import { AddressVerificationMethod, VerificationState } from '@stadtlandnetz/core';
import { GenericCard, checkmark_checked, checkmark_unchecked, svgLoader } from '@stadtlandnetz/design';
import { _ } from 'svelte-i18n';
import { sineOut } from 'svelte/easing';
import { tweened } from 'svelte/motion';
import { isBankAccountSectionEnabled } from '../../helpers';
import { addressStatuses, addressVerificationMethod, bankAccount, isSelfApplicant, mailStatus, requestPageConfiguration, sentTransportRequests, students, } from '../../stores/data.stores';
const progress = tweened(100, { easing: sineOut });
let sections = {
    register: {
        caption: 'landingPage.cards.onboarding.register',
        completed: true,
        required: true,
    },
    confirmEmail: {
        caption: 'landingPage.cards.onboarding.confirmEmail',
        completed: true,
        required: true,
        link: '/account/verification',
    },
    confirmAddress: {
        caption: 'landingPage.cards.onboarding.confirmAddress',
        completed: true,
        required: true,
        link: '/account/verification',
    },
    addChild: {
        caption: 'landingPage.cards.onboarding.addChild',
        completed: true,
        required: !$isSelfApplicant,
        link: '/account/kinder',
    },
    addBankAccount: {
        caption: 'landingPage.cards.onboarding.addBankaccount',
        completed: true,
        required: true,
        link: '/account/bankverbindung',
    },
    submitRequest: {
        caption: 'landingPage.cards.onboarding.submitRequest',
        completed: true,
        required: true,
        link: '/antraege',
    },
};
$: if ($mailStatus &&
    $addressStatuses &&
    $addressVerificationMethod &&
    $students &&
    $requestPageConfiguration &&
    $sentTransportRequests) {
    progress.set(0, { duration: 0 });
    sections.confirmEmail.completed = ($mailStatus === null || $mailStatus === void 0 ? void 0 : $mailStatus.state) === VerificationState.VERIFIED;
    sections.confirmAddress.completed = $addressStatuses === null || $addressStatuses === void 0 ? void 0 : $addressStatuses.every((status) => status.state === VerificationState.VERIFIED);
    sections.confirmAddress.caption =
        $addressVerificationMethod === AddressVerificationMethod.LETTER
            ? 'landingPage.cards.onboarding.confirmAddress'
            : 'landingPage.cards.onboarding.confirmingAddress';
    sections.confirmAddress.required = $addressVerificationMethod !== AddressVerificationMethod.NO_CHECK;
    sections.addChild.completed = $students.length > 0;
    sections.addBankAccount.completed =
        Object.values($bankAccount).length > 0 && Object.values($bankAccount).every((value) => value !== '');
    sections.addBankAccount.required =
        $requestPageConfiguration && isBankAccountSectionEnabled($requestPageConfiguration);
    sections.submitRequest.completed = $sentTransportRequests.length > 0;
    updateProgress();
}
function updateProgress() {
    const prog = Math.floor((Object.values(sections).filter((section) => section.completed && section.required).length /
        Object.values(sections).filter((section) => section.required).length) *
        100);
    progress.set(prog, prog === 100 ? { duration: 0 } : {});
}
</script>

{#if $progress < 100}
    <GenericCard topBlueBorder padding="onboarding">
        <div slot="bottom" class="onboarding">
            <div class="title">
                <span>{$_('landingPage.cards.onboarding.getStarted')}</span>
                <span>{Math.floor($progress)}%</span>
            </div>
            <div class="progressbar" style={`--progress: ${$progress}%`} />
            {#each Object.values(sections).filter((section) => section.required) as section}
                <div class="section">
                    <div class="icon">
                        {#if section.completed}
                            <img src={checkmark_checked} alt="Checkmark" use:svgLoader />
                        {:else}
                            <img src={checkmark_unchecked} alt="Checkmark unchecked" />
                        {/if}
                    </div>
                    <span
                        on:click={() => !section.completed && section.link && $goto(section.link)}
                        class:completed={section.completed}
                    >
                        {$_(section.caption)}
                    </span>
                </div>
            {/each}
        </div>
    </GenericCard>
{/if}

<style lang="stylus">.onboarding {
  font-size: var(--small);
}
.onboarding .title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.onboarding .progressbar {
  position: relative;
  height: 10px;
  border-radius: 5px;
  margin: 10px 0 20px;
  background-color: var(--color-primary-light);
}
.onboarding .progressbar:before {
  content: '';
  position: absolute;
  width: var(--progress);
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 5px;
}
.onboarding .section {
  display: flex;
  text-align: left;
  line-height: 20px;
  margin-bottom: 15px;
}
.onboarding .section .icon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding .section .icon img {
  height: 15px;
  width: 15px;
}
.onboarding .section span {
  margin-left: 7.5px;
}
.onboarding .section span:not(.completed):hover {
  cursor: pointer;
}
.onboarding .section span.completed {
  text-decoration: line-through;
  color: var(--font-color-grayish-blue);
}</style>
