<script lang="ts">import { _ } from 'svelte-i18n';
export let error;
// TODO: Refactor as store
const translations = {
    'should not be empty': 'forms.error-messages.empty',
    'must be an email': 'forms.error-messages.mail',
    'must be a postal code': 'forms.error-messages.zip',
    'must be a BIC or SWIFT code': 'forms.error-messages.bank',
    'must be an IBAN': 'forms.error-messages.iban',
    'must be a valid phone number': 'forms.error-messages.phone',
    'must be shorter than or equal to characters': 'forms.error-messages.shorter',
    'must be longer than or equal to characters': 'forms.error-messages.minLength',
    'must contain an uppercase letter': 'forms.error-messages.upper',
    'must be the same as your password': 'forms.error-messages.same',
    'must match regular expression': 'forms.error-messages.regex',
    'must be the same as your new email': 'forms.error-messages.sameEmail',
    'must be otp': 'forms.error-messages.otp',
    'must be a valid enum value': 'forms.error-messages.empty',
    'has to be given': 'forms.error-messages.emptyReason'
};
function translate(message) {
    const numRegex = /\d+/g;
    let ints;
    while ((ints = numRegex.exec(message)) != null) {
        message = message.replace(` ${ints[0]}`, '');
        return { id: translations[message], values: { length: ints[0] } } || message;
    }
    return translations[message] || message;
}
/**
 * Removes the field name from error messages produced by `class-validator`.
 * @example
 * const message = 'firstName should not be empty'
 * removeFieldName(message) === 'should not be empty'
 * @param message
 * @return {string}
 */
function removeFieldName(message) {
    return message.split(' ').slice(1).join(' ');
}
const message = error ? translate(removeFieldName(error)) : '';
</script>

{#if message}
    <span>{$_(message)}</span>
{/if}

<style lang="stylus">span {
  color: var(--color-danger);
  margin: 0;
  font-size: var(--smaller);
}</style>