<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Navigation from './Navigation.svelte';
;
import { Direction, GenericRouteLegType, GenericPinType, GenericRouteRequest, Timetable, TransportType } from '@stadtlandnetz/core';
import { addDays, format, getISOWeek, subDays } from 'date-fns';
import { TransportMap, arrow_right_blue, dashed_line } from '@stadtlandnetz/design';
import { tick, createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
import { api } from '../../services/api.service';
import { loading } from '../../stores/api.stores';
import { formatDistance, getLegDuration, getSortedJourneys } from '../../helpers';
export let locale;
export let detailEntry;
export let timetable;
export let mobile = false;
const dispatch = createEventDispatcher();
let day;
let entries;
let selected;
let rendered;
let getMarkerUrl;
let getTypeIconUrl;
let zoomToRouteLeg;
let flyTo;
let journeyElement;
let scrolledTop = true;
let scrolledBottom = true;
let innerWidth;
function selectEntry(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        if (entry === selected)
            return;
        // Route for FSV is calculated when opening the detail view
        // TODO: change endpoint -> pass only necessary info (?)
        if (entry.type === TransportType.Individual && !entry.route) {
            const request = {
                type: TransportType.Individual,
                entrance: entry.startPin,
                exit: entry.exitPin,
                individualTransportOptions: { vehicle: 'car' }
            };
            $loading = 'blocking';
            const route = (yield api.routing.route(request))[0];
            $loading = undefined;
            route.legs[0].origin = {
                type: GenericPinType.Stop,
                name: entry.start
            };
            route.legs[route.legs.length - 1].destination = {
                type: GenericPinType.Stop,
                name: entry.exit
            };
            entry.route = route;
        }
        selected = entry;
    });
}
function selectDay(selection, selectedEntry) {
    return __awaiter(this, void 0, void 0, function* () {
        // Check if selected day causes week change
        const weekSelection = selection.getDay() < 1 ? subDays(selection, 2)
            : selection.getDay() > 5 ? addDays(selection, 2)
                : day && getISOWeek(day) !== getISOWeek(selection) ? selection
                    : undefined;
        if (weekSelection) {
            day = weekSelection;
            dispatch('changeWeek', weekSelection);
            return;
        }
        day = selection;
        entries = getSortedJourneys(timetable[format(day, 'EEEE').toLowerCase()]);
        if (!entries.length)
            return;
        yield selectEntry(selectedEntry !== null && selectedEntry !== void 0 ? selectedEntry : entries[0]);
    });
}
function handleScrollShadows(waitForDomUpdate = false) {
    return __awaiter(this, void 0, void 0, function* () {
        if (waitForDomUpdate)
            yield tick();
        const scrollTop = journeyElement.scrollTop;
        scrolledTop = scrollTop === 0;
        scrolledBottom = scrollTop === journeyElement.scrollHeight - journeyElement.clientHeight;
    });
}
function addTitlesToSpans() {
    return __awaiter(this, void 0, void 0, function* () {
        yield tick();
        journeyElement.querySelectorAll('span').forEach(span => span.title = span.innerHTML);
    });
}
$: if (journeyElement && selected) {
    journeyElement.scrollTop = 0;
    handleScrollShadows(true);
    addTitlesToSpans();
}
$: if (detailEntry) {
    selectDay(detailEntry.begin, detailEntry);
    detailEntry = null;
}
else {
    selected = undefined;
}
$: mobile = innerWidth <= 700;
</script>

<svelte:window bind:innerWidth />

<div class="detail">
    {#if day}
        <div class="top">
            <Navigation
                hideToday={mobile}
                on:back={() => selectDay(subDays(day, 1))}
                on:today={() => selectDay(new Date())}
                on:forward={() => selectDay(addDays(day, 1))}
            />
            <span class="date">
                {format(day, 'EEEE dd. MMMM yyyy', { locale })}
            </span>
        </div>
    {/if}
    {#if selected && entries.length}
        <div class="content">
            {#if rendered} <!-- Necessary for call to getMarkerUrl() -->
                <div class="left">
                    <div class="tabs">
                        {#each entries as entry, i}
                            <div
                                class="tab"
                                class:active={selected === entry}
                                on:click={() => selectEntry(entry)}
                            >
                                <span>
                                    {$_(entry.direction === Direction.TO
                                        ? 'timetable.outward'
                                        : 'timetable.return')}
                                </span>
                            </div>
                        {/each}
                    </div>
                    <div class="scrollShadows" class:top={!scrolledTop} class:bottom={!scrolledBottom}></div>
                    <div
                        class="journey"
                        bind:this={journeyElement}
                        on:scroll={() => handleScrollShadows()}
                    >
                        {#each selected.route.legs as leg, index}
                            <div class="station">
                                {#if index === 0}
                                    <img
                                        class="marker"
                                        src={getMarkerUrl('green')}
                                        alt="marker"
                                        title={!mobile ? $_('timetable.showOnMap') : ''}
                                        on:click={() => !mobile && flyTo(leg.path[0])}
                                    />
                                {:else}
                                    <div
                                        class="dot"
                                        title={!mobile ? $_('timetable.showOnMap') : ''}
                                        on:click={() => !mobile && flyTo(leg.path[0])}
                                    />
                                {/if}
                                <div class="info">
                                    <span class="name">
                                        {#if index === 0}
                                            {selected.direction === Direction.TO
                                                ? $_('timetable.home')
                                                : selected.schoolName}
                                        {:else}
                                            {leg.origin.type === GenericPinType.Stop ? leg.origin.name : ''}
                                        {/if}
                                    </span>
                                    {#if index === 0}
                                        <span class="address">{selected.start.replace(', ', ',\n')}</span>
                                    {:else if leg.originPlatform}
                                        <span class="address">
                                            {$_('timetable.platform', {
                                                values: { number: leg.originPlatform }
                                            })}
                                        </span>
                                    {/if}
                                </div>
                                <span class="time">
                                    {format(index === 0 ? selected.begin : leg.departure, 'HH:mm')}
                                </span>
                            </div>

                            <div class="leg" class:walking={leg.type === GenericRouteLegType.Walking}>
                                <img
                                    class="type"
                                    src={getTypeIconUrl(leg.type)}
                                    alt="type"
                                    title={!mobile ? $_('timetable.showOnMap') : ''}
                                    on:click={() => !mobile && zoomToRouteLeg(index)}
                                />
                                {#if leg.type === GenericRouteLegType.Walking}
                                    <img class="line" src={dashed_line} alt="dashed line"/>
                                {:else}
                                    <div class="line" />
                                {/if}
                                <div class="info">
                                    <img src={arrow_right_blue} alt="arrow right" />
                                    <div>
                                        {#if selected.type === TransportType.Individual}
                                            <span class="title">{selected.company}</span>
                                            <span class="additional">{selected.tour}</span>
                                        {:else if leg.type === GenericRouteLegType.Walking && leg.distance}
                                            <span class="title">
                                                {$_('timetable.walking', {
                                                    values: { distance: formatDistance(leg.distance) }
                                                })}
                                            </span>
                                        {:else}
                                            <span class="title">{leg.line ?? ''} {leg.direction ?? ''}</span>
                                        {/if}
                                    </div>
                                </div>
                                <span class="time">
                                    {selected.type === TransportType.Individual
                                        ? getLegDuration(selected)
                                        : getLegDuration(leg)}
                                </span>
                            </div>

                            {#if index === selected.route.legs.length - 1}
                                <div class="station">
                                    <img
                                        class="marker"
                                        src={getMarkerUrl('red')}
                                        alt="marker"
                                        title={!mobile ? $_('timetable.showOnMap') : ''}
                                        on:click={() => !mobile && flyTo(leg.path.slice(-1)[0])}
                                    />
                                    <div class="info">
                                        <span class="name">
                                            {selected.direction === Direction.TO
                                                ? selected.schoolName
                                                : $_('timetable.home')}
                                        </span>
                                        <span class="address">{selected.exit.replace(', ', ',\n')}</span>
                                    </div>
                                    <span class="time">{format(selected.end, 'HH:mm')}</span>
                                </div>
                            {/if}
                        {/each}
                    </div>
                </div>
            {/if}
            <div class="map">
                <TransportMap
                    bind:route={selected.route}
                    bind:rendered
                    bind:getMarkerUrl
                    bind:getTypeIconUrl
                    bind:zoomToRouteLeg
                    bind:flyTo
                />
            </div>
        </div>
    {:else}
        <div class="content noEntries">{$_('timetable.noJourneys')}</div>
    {/if}
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.detail {
  padding: 23px 30px 32px;
  background-color: #fff;
  border-radius: 5px;
}
.detail > .top {
  display: flex;
  align-items: center;
  margin: 0 40px 22px 0;
}
.detail > .top .date {
  font-size: var(--subheading);
  font-weight: bold;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 800px;
  height: 440px;
}
.detail .content.noEntries {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--normal);
}
.detail .content > div {
  border: solid 1px var(--color-light-gray);
  border-radius: 5px;
  overflow: hidden;
}
.detail .content .left {
  border-bottom-right-radius: 0;
  position: relative;
}
.detail .content .left .tabs {
  display: flex;
  height: 40px;
}
.detail .content .left .tabs .tab {
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f9fafb;
  overflow: hidden;
  border-bottom: solid 1px var(--color-light-gray);
}
.detail .content .left .tabs .tab:not(:last-child) {
  border-right: solid 1px var(--color-light-gray);
}
.detail .content .left .tabs .tab.active {
  background: transparent;
  cursor: initial;
  font-weight: bold;
  border-bottom-color: transparent;
}
.detail .content .left .tabs .tab span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail .content .left .journey {
  height: calc(100% - 40px);
  overflow: auto;
}
.detail .content .left .journey .station,
.detail .content .left .journey .leg {
  display: grid;
  grid-template-columns: 24px auto auto;
  align-items: center;
  box-sizing: border-box;
}
.detail .content .left .journey .station > :first-child,
.detail .content .left .journey .leg > :first-child {
  z-index: 2;
}
.detail .content .left .journey .station {
  height: 100px;
  padding: 20px 30px;
}
.detail .content .left .journey .station > :first-child {
  margin: auto;
}
.detail .content .left .journey .station .marker {
  width: 22px;
  cursor: pointer;
}
.detail .content .left .journey .station .dot {
  width: 8px;
  height: 8px;
  border: solid 4px var(--color-primary);
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}
.detail .content .left .journey .station .info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
  height: 100%;
}
.detail .content .left .journey .station .info span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail .content .left .journey .station .info .name {
  font-weight: bold;
  font-size: var(--small);
}
.detail .content .left .journey .station .info .address {
  color: var(--font-color-darker-gray);
  font-size: var(--smaller);
  white-space: pre;
  line-height: 15px;
}
.detail .content .left .journey .station .time {
  font-size: var(--smaller);
  font-weight: bold;
  margin-left: auto;
}
.detail .content .left .journey .leg {
  position: relative;
  height: 50px;
  background: #f9fafb;
  padding: 0 30px;
}
.detail .content .left .journey .leg .type {
  cursor: pointer;
}
.detail .content .left .journey .leg .line {
  position: absolute;
  width: 4px;
  height: 150px;
  left: 40px;
  background: var(--color-primary);
  box-sizing: border-box;
}
.detail .content .left .journey .leg.walking .line {
  background: none;
  transform: translateY(-2px);
}
.detail .content .left .journey .leg .info {
  margin-left: 20px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
.detail .content .left .journey .leg .info div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 10px;
}
.detail .content .left .journey .leg .info div span {
  font-size: var(--smaller);
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail .content .left .journey .leg .info div .title {
  font-weight: bold;
}
.detail .content .left .journey .leg .info div .additional {
  color: var(--font-color-darker-gray);
}
.detail .content .left .journey .leg .time {
  font-size: var(--smaller);
  color: var(--font-color-darker-gray);
  margin-left: auto;
}
.detail .content .left .scrollShadows {
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  top: 40px;
  pointer-events: none;
}
.detail .content .left .scrollShadows:before,
.detail .content .left .scrollShadows:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: 3;
}
.detail .content .left .scrollShadows:before {
  background: linear-gradient(rgba(156,174,192,0.25), rgba(0,0,0,0)) center top no-repeat;
}
.detail .content .left .scrollShadows:after {
  background: linear-gradient(rgba(0,0,0,0), rgba(156,174,192,0.25)) center bottom no-repeat;
  bottom: 0;
}
.detail .content .left .scrollShadows.top:before,
.detail .content .left .scrollShadows.bottom:after {
  opacity: 1;
  transition: opacity 0.3s ease !important;
}
.detail .content .map {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 40px 0 0 -1px;
}
@media screen and (max-width: 1100px) {
  .detail .content {
    width: 700px;
  }
}
@media screen and (max-width: 960px) {
  .detail .content {
    width: 600px;
  }
  .detail .content .left .tabs .tab {
    padding: 0 4px;
  }
}
@media screen and (max-width: 850px) {
  .detail .content {
    width: 500px;
  }
}
@media screen and (max-width: 700px) {
  .detail {
    padding: 15px;
  }
  .detail > .top {
    margin-bottom: 15px;
  }
  .detail > .top .date {
    font-size: var(--small);
  }
  .detail .content {
    grid-template-columns: 1fr;
    height: 65vh;
    width: auto;
  }
  .detail .content .left {
    border-bottom-right-radius: 5px;
  }
  .detail .content .left .journey .station .marker,
  .detail .content .left .journey .station .dot,
  .detail .content .left .journey .leg .type {
    cursor: initial;
  }
  .detail .map {
    display: none;
  }
}</style>
