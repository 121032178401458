<script lang="ts">import { _, locale } from 'svelte-i18n';
import { format } from 'date-fns';
import { createEventDispatcher } from 'svelte';
import { Submenu } from '../';
import { TransportRequestState } from '../../../core/src';
export let highlightedText;
export let text;
export let date = null;
export let status;
export let transportRequestDecisionVisibility = false;
export let name;
export let id;
export let decisionDocuments = [];
const dispatch = createEventDispatcher();
$: if ($locale)
    dispatch('getTitle');
function removeParentClickEvent(event) {
    event.stopPropagation();
}
</script>

<div class="card clickable {status}" on:click={() => dispatch('open')}>
    <div class="left">
        <div class="title">
            {#if status !== 'draft'}
                <span class="{status}-font bold">{highlightedText}:</span>
            {/if}
            <span>{text}</span>
        </div>

        <div class="info">
            {#if date !== null}
                <span>{format(date, 'dd.MM.yyyy')}</span>
                <span>{name}</span>
            {/if}
        </div>
    </div>
    <div class="dropdown" on:click={removeParentClickEvent}>
        <Submenu {id}>
            <div class="submenu">
                {#if transportRequestDecisionVisibility && decisionDocuments.length > 0}
                    {#each decisionDocuments as document, index}
                        <div on:click={() => dispatch('saveNotice', { documentId: document.id })}>
                            {#if decisionDocuments.length === 1}
                                {$_('requests.save-notice')}
                            {:else}
                                {$_('requests.save-notices', { values: { index: `${index + 1}` } })}
                            {/if}
                        </div>
                    {/each}
                {/if}
                {#if status !== 'draft'}
                    <div class="bold" on:click={() => dispatch('open')}>
                        {$_('requests.open-request')}
                    </div>
                {:else}
                    <div on:click={() => dispatch('open')}>
                        {$_('requests.openDraft')}
                    </div>
                    <div
                        aria-label="Delete draft"
                        on:click={() => dispatch('deleteDraft')}
                        class="deleteDraftButton"
                        style="color: var(--color-danger)"
                    >
                        {$_('requests.deleteDraft')}
                    </div>
                {/if}
            </div>
        </Submenu>
    </div>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
.card {
  min-height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  transition: box-shadow 0.3s;
}
.card:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
}
.left {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title span {
  font-size: var(--normal);
  text-transform: capitalize;
}
.bold {
  font-weight: bold;
}
.info {
  font-size: var(--small);
  color: var(--font-color-darker-gray);
}
.info span:first-child {
  margin-right: 20px;
}
.draft {
  border-left: 5px solid var(--font-color-grayish-blue);
}
.draft-font {
  color: var(--font-color-grayish-blue);
}
.approved {
  border-left: 5px solid var(--color-success);
}
.approved-font {
  color: var(--color-success);
}
.rejected {
  border-left: 5px solid var(--color-danger);
}
.rejected-font {
  color: var(--color-danger);
}
.in_validation {
  border-left: 5px solid var(--color-yellow);
}
.in_validation-font {
  color: var(--color-yellow);
}
.probably_approved_change {
  border-left: 5px solid var(--color-yellow);
}
.probably_approved_change-font {
  color: var(--color-yellow);
}
.probably_rejected_change {
  border-left: 5px solid var(--color-yellow);
}
.probably_rejected_change-font {
  color: var(--color-yellow);
}
.not_pre-evaluated {
  border-left: 5px solid var(--color-yellow);
}
.not_pre-evaluated-font {
  color: var(--color-yellow);
}
.self_pay {
  border-left: 5px solid var(--font-color-darker-gray);
}
.self_pay-font {
  color: var(--font-color-darker-gray);
}
.buttons {
  display: grid;
  grid-template-columns: 150px 150px;
  column-gap: 15px;
  margin-left: 30px;
}
.clickable {
  cursor: pointer;
}
.dropdown {
  display: initial;
  border-radius: 5px;
}
.dropdown:hover {
  background-color: var(--color-primary-lightest);
}
.submenu {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  background-color: var(--color-white);
  width: 180px;
}
.submenu > div {
  border-bottom: 1px solid var(--color-light-gray);
  height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.submenu > div:last-child {
  border-bottom: none;
}
.submenu > div:hover {
  background-color: var(--color-primary-lightest);
}
.deleteDraftButton:hover {
  background-color: #fcf3f4 !important;
}
@media screen and (max-width: 800px) {
  .card {
    padding: 20px 20px 20px 30px;
    min-height: auto;
  }
  .date {
    display: none;
  }
  .title {
    font-size: var(--small);
  }
  .date-and-status {
    margin-top: 10px;
  }
  .buttons {
    display: none;
  }
  .left {
    row-gap: 5px;
  }
}</style>
