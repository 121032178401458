<script lang="ts">var _a;
import { createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
;
import { clickOutside } from '../helpers';
export let id = '';
export let name = '';
export let classes = '';
export let options = [];
export let disabled = false;
export let value;
let selectedOption = (_a = options.find((option) => option.value == value)) !== null && _a !== void 0 ? _a : null;
let show = false;
let filterValue = '';
let filteredOptions = [];
$: filteredOptions = options;
const dispatch = createEventDispatcher();
$: Array.isArray(options) && resetValueIfNotPresentInOptions(options);
// If the options given to the Select are changed, we reset the value to null if the currently
// selected value is not available in the options anymore
function resetValueIfNotPresentInOptions(options) {
    if (!options.find((option) => option.value === value))
        selectedOption = null;
}
$: if (selectedOption != null) {
    value = selectedOption.value;
}
else {
    value = null;
}
function getOptionText(option) {
    return option.hasOwnProperty('subtitle')
        ? `${option.title} ${option.subtitle}`
        : option.title;
}
function filterFunction() {
    const tokens = filterValue.toUpperCase().split(' ');
    filteredOptions = options.filter((option) => {
        const optionText = getOptionText(option).toUpperCase();
        return tokens.every((token) => optionText.includes(token));
    });
}
function toggleDropdown(e) {
    var _a, _b;
    if (!disabled && ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.className) === null || _b === void 0 ? void 0 : _b.includes('close'))) {
        show = !show;
    }
}
</script>

<div
    class={`background close ${classes}`}
    class:bottomAppend={show}
    use:clickOutside
    on:outclick={() => (show = false)}
    on:click={toggleDropdown}
    class:disabled
>
    <span class="selected close">
        {selectedOption ? selectedOption.title : ''}
    </span>
    {#if show && !disabled}
        <div class="dropdown">
            <input
                class="search"
                type="text"
                placeholder={$_('forms.dropdownSearch')}
                autocomplete="school-search"
                bind:value={filterValue}
                on:input={filterFunction}
                on:focus
                on:blur
            />
            <div class="options">
                {#each filteredOptions.sort( (o1, o2) => (o1.title >= o2.title ? 1 : -1) ) as option, index}
                    {#if index > 0}
                        <hr />
                    {/if}
                    <div
                        class="option multiline"
                        on:click={() => {
                            selectedOption = option
                            dispatch('changeValue', {
                                name,
                                value: option.value
                            })
                            filterValue = ''
                            show = false
                        }}
                    >
                        <span class="schoolName">
                            {option.title}
                        </span>
                        <span class="address">
                            {option.subtitle}
                        </span>
                    </div>
                {/each}
            </div>
        </div>
    {/if}
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
.dropdown {
  background-color: #fff;
  z-index: 1001;
  position: absolute;
  top: 100%;
  left: -1px;
  width: 100%;
  max-height: 290px;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--color-light-gray);
  display: grid;
}
.options {
  overflow: scroll;
  padding: 0 5px;
  margin-top: 5px;
  max-height: 236px;
}
.options hr {
  height: 1px;
  background: var(--color-light-gray);
  margin: 1px 0;
  border: none;
  display: none;
}
.options .option {
  white-space: nowrap;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 1px solid transparent;
  padding-left: 15px;
  border-radius: 5px;
}
.options .option:last-child {
  margin-bottom: 5px;
}
.options .option span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.options .option.selected {
  background-color: var(--color-gray-background);
  border-color: var(--color-light-gray);
}
.options .option:hover,
.options .option.active {
  background-color: var(--color-lighter-gray);
  border-color: var(--color-light-gray);
}
@media screen and (max-width: 1105px) {
  .options hr {
    display: block;
  }
  .options .option.multiline {
    height: 50px;
    flex-direction: column;
    font-size: var(--small);
    align-items: initial;
    padding: 0 10px;
  }
  .options .option.multiline span {
    margin-left: 0;
  }
}
@media screen and (max-width: 525px) {
  .options .option {
    padding: 0 5px;
  }
}
.background {
  position: relative;
  display: grid;
  grid-template-areas: "select";
  height: 40px;
  padding: 0 30px 0 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid var(--color-light-gray);
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
.background.success {
  border: 1px solid var(--color-success);
}
.background.error {
  border: 1px solid var(--color-danger);
}
.background:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 0.5em;
  background: no-repeat url("data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e%3csvg width='12px' height='6px' viewBox='0 0 12 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3c!-- Generator: Sketch 64 (93537) - https://sketch.com --%3e %3ctitle%3eMisc / Arrow down Copy%3c/title%3e %3cdesc%3eCreated with Sketch.%3c/desc%3e %3cg id='Misc-/-Arrow-down-Copy' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3e %3cpolyline id='Line-Copy-3' stroke='black' stroke-width='2' transform='translate(6.000000%2c 3.000000) scale(-1%2c 1) translate(-6.000000%2c -3.000000) ' points='1 1 6.05076272 5 11 1'%3e%3c/polyline%3e %3c/g%3e%3c/svg%3e");
  margin: 2px 10px 0 0;
  justify-self: end;
}
.background.bottomAppend {
  border-radius: 5px 5px 0 0;
}
.background.disabled {
  background-color: var(--color-gray-background);
  color: var(--font-color-darker-gray);
  cursor: initial;
}
.background.disabled:after {
  opacity: 0.3;
}
.background .selected {
  font-size: var(--normal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.background .search {
  height: 40px;
  width: 98%;
  padding: 0 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  justify-self: center;
  margin-top: 5px;
}
.background .search:focus {
  outline: none;
  box-shadow: 1px 1px 0 var(--color-primary), -1px -1px 0 var(--color-primary), -1px 1px 0 var(--color-primary), 1px -1px 0 var(--color-primary);
}
.address {
  color: var(--font-color-darker-gray);
}
@media screen and (max-width: 1105px) {
  .address {
    font-size: var(--smaller);
  }
}
@media screen and (max-width: 800px) {
  .background {
    height: 50px;
  }
  .background .search {
    height: 50px;
  }
}</style>
