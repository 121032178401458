<script lang="ts">import Badge from '../../badge/Badge.svelte';
import { _ } from 'svelte-i18n';
import { createEventDispatcher } from 'svelte';
;
export let sections;
export let sectionOrder;
export let activeElement;
export let showSaveButton = false;
export let showDeleteButton = false;
export let showEvaluationSection = false;
export let showVerificationSection = false;
export let showEvaluation = false;
export let showVerification = false;
export let showConfirmation = false;
export let complete = false;
const dispatch = createEventDispatcher();
function badgeType(section) {
    return section.state == 'success' ? 'positive' : section.state;
}
</script>

<aside>
    <ul class="card">
        {#each Object.entries(sections) as [key, section] (section.name)}
            <li
                class={badgeType(section)}
                class:activeSection={sectionOrder[activeElement] === key &&
                    !showEvaluation &&
                    !showConfirmation &&
                    !showVerification}
            >
                <div class="li-content" on:click={() => dispatch('loadSection', sectionOrder.indexOf(key))}>
                    {$_(section.name)}
                    <div class="badge">
                        <Badge type={badgeType(section)} generatedLabels={true} level={3} />
                    </div>
                </div>
            </li>
        {/each}
        {#if showEvaluationSection}
            <li class="empty" class:evaluation={complete} class:activeSection={showEvaluation}>
                <div
                    class="li-content"
                    on:click={() => {
                        showEvaluation = true
                        activeElement = undefined
                        dispatch('saveDraft')
                    }}
                >
                    {$_('requests.evaluation.title')}
                </div>
            </li>
        {/if}
        {#if showVerificationSection}
            <li
                class="empty"
                style="cursor: initial; pointer-events: none"
                class:grayText={!showVerification}
                class:activeSection={showVerification}
            >
                <div class="li-content">
                    {$_('navigation.verification')}
                </div>
            </li>
        {/if}
        {#if showEvaluationSection && !showVerificationSection}
            <li
                class="empty"
                style="cursor: initial; pointer-events: none;"
                class:grayText={!showConfirmation}
                class:activeSection={showConfirmation && !showEvaluation}
            >
                <div class="li-content">
                    {$_('requests.confirmation.title')}
                </div>
            </li>
        {/if}
        <slot />
    </ul>
</aside>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
:root.default-theme {
  --color-primary: #5d95f2;
  --color-success: #3ecf8e;
  --color-warning: #ffc100;
  --color-danger: #f95651;
  --color-primary-hover: #4889f0;
  --color-success-hover: #32c985;
  --color-danger-hover: #f8423c;
  --color-primary-light: #dfeafc;
  --color-primary-lighter: #eef4fc;
  --color-primary-lightest: #f6f9fe;
  --snackbar-success: #7fde81;
  --snackbar-warning: #ffe58d;
  --color-gray-background: #f6f9fa;
  --color-box-shadow: rgba(30,43,80,0.1);
  --color-white: #fff;
  --color-white-hover: #f4f8fe;
  --color-black: #000;
  --color-gray: #eef3f5;
  --color-light-gray: #eceef0;
  --color-light-gray-fills: #eceef0;
  --color-lighter-gray: #f1f4f5;
  --color-dark-gray: #dde5ec;
  --color-darker-gray: #ccc;
  --color-darkest-gray: #666;
  --color-green: #3ecf8e;
  --color-green-background: #ebfaf3;
  --color-light-green: #d7f5e8;
  --color-teal: #3ed09a;
  --color-dark-teal: #37be8d;
  --color-blue: #5d95f2;
  --color-mid-blue: #c5d6e7;
  --color-dark-blue: #212f68;
  --color-dark-blue-bundid: #004f80;
  --color-dark-blue-bundid-hover: #00436d;
  --color-dark: #35468a;
  --color-red: #f95651;
  --color-light-red: #feeeec;
  --color-yellow: #f5a623;
  --color-light-yellow: #fcfce9;
  --font-color-gray: #9caec0;
  --font-color-light-gray: #c2cad2;
  --font-color-darker-gray: #8697a8;
  --font-color-black-gray: #333;
  --font-color-register: #fff;
  --font-color-dark-blue: #212f68;
  --font-color-footer-stamp: rgba(93,149,242,0.3);
  --font-color-grayish-blue: #576a7d;
  --font-color-dark-grayish-blue: #313c48;
}
:root.dvg-theme {
  --color-primary: #bf111b;
  --color-primary-hover: #a80f18;
  --color-primary-light: #f2cfd1;
  --color-primary-lighter: #f9e7e8;
  --color-primary-lightest: #fcf3f4;
  --color-dark: #890004;
}
:root.dvb-theme {
  --color-primary: #fdc400;
  --color-primary-hover: #eeb600;
  --color-primary-light: #fff3cc;
  --color-primary-lighter: #fff9e6;
  --color-primary-lightest: #fffcf3;
}
:root.dark-theme {
  --color-primary: #000;
  --color-success: #000;
  --color-warning: #fff;
  --color-danger: #000;
  --color-primary-hover: #000;
  --color-success-hover: #000;
  --color-danger-hover: #000;
  --background-color-danger: #000;
  --color-box-shadow: #000;
  --color-white: #fff;
  --color-white-hover: #fff;
  --color-black: #000;
  --color-light-gray: #000;
  --color-dark-gray: #000;
  --color-green: #000;
  --color-red: #000;
  --color-yellow: #000;
  --font-color-light-gray: #000;
  --font-color-darker-gray: #000;
  --font-color-dark-blue: #000;
  --font-color-register: #fff;
  --font-color-footer-stamp: #fff;
}
body,
html {
  font-family: var(--font-family);
  font-size: var(--small);
  max-width: 100%;
  overflow-x: hidden;
  line-height: 24px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-background);
}
.card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--color-light-gray);
  min-height: 4em;
}
.bigtitle {
  font-weight: bold;
  font-size: var(--bigtitle);
  line-height: 53px;
}
label {
  cursor: pointer;
}
label[disabled="true"] {
  cursor: default !important;
}
input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary) !important;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  min-width: 14px;
  min-height: 14px;
  border: 2px solid #9595a2;
  vertical-align: middle;
  top: -1px;
  cursor: pointer;
}
input[type="radio"]:enabled:hover,
input[type="checkbox"]:enabled:hover {
  border-color: #676774;
}
input[type="radio"]:enabled:active,
input[type="checkbox"]:enabled:active {
  border-color: #484851;
}
input[type="radio"]:enabled:checked:hover,
input[type="checkbox"]:enabled:checked:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover) !important;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: default;
}
input[type="radio"] {
  padding: 2px;
  background-clip: content-box;
  border-radius: 50%;
}
input[type="checkbox"] {
  border-radius: 3px;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-backface-visibility: hidden /* Prevent aliasing on chrome */;
  transform: skew(15deg, -15deg) rotate(50deg) translateX(38%) translateY(-18%);
}
input[type="checkbox"]:checked:after {
  display: block;
}
.checkOptions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 10px;
  row-gap: 5px;
/* Prevent label text from wrapping under radio / checkbox */
}
.checkOptions label {
  padding-left: 24px;
}
.checkOptions label input {
  margin-left: -24px;
  margin-right: 6px;
}
@media screen and (max-width: 800px) {
  input[type="radio"],
  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    top: 0;
  }
  input[type="checkbox"]:after {
    width: 4px;
    height: 8px;
  }
  .checkOptions label {
    padding-left: 32px;
  }
  .checkOptions label input {
    margin-left: -32px;
    margin-right: 12px;
  }
}
aside {
  width: 100%;
  border-radius: 5px;
  background-color: var(--color-white);
  max-width: 320px;
}
ul {
  padding-left: 0;
  margin: 0;
  border-radius: 5px;
}
li {
  height: 60px;
  list-style: none;
  line-height: 24px;
  font-size: var(--small);
  position: relative;
  border-left: 3px solid;
  color: var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
li:first-child {
  border-top-left-radius: 3px;
}
li:last-child {
  border-bottom-left-radius: 3px;
  border-bottom: none;
}
li:hover {
  background-color: var(--color-gray-background);
}
li.positive {
  border-left-color: var(--color-green);
}
li.error {
  border-left-color: var(--color-red);
}
li.activeSection {
  border-left: 3px solid var(--color-black) !important;
  font-weight: bold;
}
li.grayText {
  color: var(--font-color-darker-gray);
}
li.empty {
  border-left: none;
}
li.evaluation {
  border-left: 3px solid var(--color-green);
}
li .li-content {
  height: 100%;
  border-bottom: 1px solid var(--color-light-gray);
  padding: 0 10px 0 30px;
  display: grid;
  grid-template-columns: 1fr 110px;
  align-items: center;
}
li .li-content .badge {
  width: 100px;
  justify-self: end;
}</style>
