<script lang="ts">import { _ } from 'svelte-i18n';
export let text = '';
export let type;
export let level = 1;
export let generatedLabels = false;
$: if (generatedLabels)
    text =
        type === 'in_process'
            ? $_('requests.sidenav.in_process')
            : type === 'empty'
                ? $_('requests.sidenav.empty')
                : type === 'incomplete'
                    ? $_('requests.sidenav.missing')
                    : type === 'error'
                        ? $_('requests.sidenav.error')
                        : $_('requests.sidenav.success');
</script>

<div class="badge {type} level-{level}">
    <span>{text}</span>
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.badge {
  border-radius: 15px;
  border: none;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.positive {
  background-color: var(--color-green-background);
  color: var(--color-green);
}
.negative,
.error {
  background-color: var(--color-light-red);
  color: var(--color-red);
}
.neutral,
.empty,
.in_process,
.incomplete {
  background-color: var(--color-gray-background);
  color: var(--font-color-darker-gray);
}
.primary {
  background-color: var(--color-primary);
}
.danger {
  background-color: var(--color-danger);
  color: var(--color-white);
}
.level-1 {
  min-width: 90px;
  padding: 0 5px;
  height: 20px;
  font-size: var(--smaller);
  line-height: 20px;
}
.level-2 {
  font-size: var(--small);
  padding: 6px 26px;
}
.level-3 {
  width: 90px;
  font-size: var(--smaller);
  line-height: 20px;
  padding: 0 5px;
}
.success {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}</style>
