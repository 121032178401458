<!-- routify:options place=4 -->
<!-- routify:options icon="bank" -->
<!-- routify:options title="account.bank.name" -->

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { BankAccount, getTranslationString } from '@stadtlandnetz/core';
import { AlertBox } from '@stadtlandnetz/design';
;
import { _, locale } from 'svelte-i18n';
import FormCard from '../../components/Cards/FormCard.svelte';
import { api } from '../../services/api.service';
;
import { loading } from '../../stores/api.stores';
import { bankAccount, bankAccountLegalText, baseUserId, infoBoxes, warning } from '../../stores/data.stores';
let bank, bankIban, status, alreadyCheckedIban;
let formErrors = {};
api.bankAccount.getMany({ id: 0, userId: $baseUserId }).then((bankAccounts) => {
    if (bankAccounts && bankAccounts.length > 0) {
        bank = bankAccounts.find((account) => account.active === true);
        bankIban = bank['iban'];
    }
});
function save() {
    return __awaiter(this, void 0, void 0, function* () {
        if (bankIban && bankIban !== '') {
            const response = yield api.bankAccount.updateEntity(bank, {
                id: bank['id'],
                userId: $baseUserId,
            });
            if (response) {
                bankAccount.set(response);
                bankIban = bank['iban'];
            }
        }
        else {
            const response = yield api.bankAccount.createOne(Object.assign(Object.assign({}, bank), { active: true }), { id: '', userId: $baseUserId });
            if (response) {
                bankAccount.set(response);
                bankIban = bank['iban'];
            }
        }
    });
}
function getBankAccountDetails() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const ibanWasChecked = bank.iban === alreadyCheckedIban;
        const ownerHasChanged = $bankAccount && ((_a = Object.values($bankAccount)) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? $bankAccount.owner !== bank.owner
            : '' !== bank.owner;
        const onlyIbanIsFilled = bank.iban !== '' && bank.bic === '' && bank.bank === '';
        const validIban = formErrors.iban === undefined;
        const ibanHasChanged = bankIban ? bankIban !== bank.iban : '' !== bank.iban;
        const searchBankDetails = validIban && (onlyIbanIsFilled || ibanHasChanged);
        if (ownerHasChanged || ibanWasChecked)
            return;
        if (searchBankDetails) {
            $loading = 'blocking';
            try {
                alreadyCheckedIban = bank.iban;
                const response = yield api.openIbanService.getBankAndBicOutOfIban(bank.iban);
                const bankAccountDetails = (yield response.json());
                if (response.status === 200) {
                    bank.bic = bankAccountDetails.bankData.bic !== undefined ? bankAccountDetails.bankData.bic : '';
                    bank.bank = bankAccountDetails.bankData.name !== undefined ? bankAccountDetails.bankData.name : '';
                }
                else {
                    warning.set($_('snackbar.openIbanError'));
                }
            }
            catch (e) {
                warning.set($_('snackbar.openIbanError'));
                console.error(e);
            }
            finally {
                $loading = undefined;
            }
        }
    });
}
$: bankAccountInfoText = getTranslationString((_a = $infoBoxes === null || $infoBoxes === void 0 ? void 0 : $infoBoxes.general) === null || _a === void 0 ? void 0 : _a.bankAccountInfo, $locale);
</script>

{#if bankAccountInfoText}
    <AlertBox type="warning" margin_bottom>
        <span>{bankAccountInfoText}</span>
    </AlertBox>
{/if}

<FormCard
    title={$_('account.bank.title')}
    disabled={Object.values($bankAccount).length > 0 || Object.values($bankAccount).every((value) => value !== '')}
    formClass={BankAccount}
    bind:formObject={bank}
    bind:formErrors
    bind:status
    on:change={() => getBankAccountDetails()}
    on:save={save}
>
    <div class="description" slot="additional-description">
        {@html getTranslationString($bankAccountLegalText, $locale)}
    </div>
</FormCard>

<style lang="stylus">.description :global(:first-child) {
  margin-top: 0px;
}</style>
