<script lang="ts">import { _ } from 'svelte-i18n';
import { differenceInMinutes, format, getMinutes } from 'date-fns';
import { Direction } from '@stadtlandnetz/core';
;
import { stripes } from '@stadtlandnetz/design';
import { createEventDispatcher } from 'svelte';
export let entry;
const dispatch = createEventDispatcher();
const entryDuration = differenceInMinutes(entry.end, entry.begin);
// 100% = 1h; 
// Height: time = 45-12 = 33m; 33/60 * 100 = 55%
// Offset: (100% / 60) * 12min => 20% 
const offset = (100 / 60) * getMinutes(entry.begin);
// Don't know why, but adding extra 3% of the value seems to be most accurate
const entryHeight = Math.round((entryDuration / 60) * 100) * 1.03;
const journeyType = entry.direction === Direction.TO ? 'timetable.outward' : 'timetable.return';
</script>

{#if entry.type !== null}
    <div
        class="entry"
        class:return={entry.direction === Direction.BACK}
        class:short={entryDuration < 70}
        style="top: {offset}%; height: {entryHeight}%"
        title={
            `${$_(journeyType)}\n` +
            `${$_('timetable.at')} ${format(entry.begin, 'HH:mm')}\n` +
            `${$_('timetable.arrives')} ${format(entry.end, 'HH:mm')}`
        }
        on:click={() => dispatch('showDetail', entry)}
    >
        <span>{$_(journeyType)}</span>
        <span class="time">{$_('timetable.at')} {format(entry.begin, 'HH:mm')}</span>
    </div>
{:else}
    <div
        class="lesson"
        style='top: {offset - 2}%; height: {entryHeight + 5}%; background-image: url("{stripes}")'
        title={
            `${$_('timetable.lessons')}\n` +
            `${$_('timetable.from')} ${format(entry.begin, 'HH:mm')}\n` +
            `${$_('timetable.until')} ${format(entry.end, 'HH:mm')}`
        }
    >
        {$_('timetable.lessons')}
    </div>
{/if}

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.entry {
  font-size: var(--smaller);
  position: absolute;
  left: 8%;
  box-sizing: border-box;
  border-radius: 5px;
  width: 84%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: solid 1px var(--color-light-gray);
  transition: all 0.1s ease;
  z-index: 9;
}
.entry:before {
  content: '';
  border-left: solid 3px var(--color-success);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
}
.entry.return:before {
  border-color: var(--color-danger);
}
.entry:after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 2px;
  width: 3px;
  border-top: inherit;
  border-bottom: inherit;
  background: inherit;
  border-top-left-radius: 2px 1px;
  border-bottom-left-radius: 2px 1px;
}
.entry:hover {
  cursor: pointer;
  background: #f2f3f5;
}
.entry:hover:after {
  background: inherit;
}
.entry span:first-child {
  font-weight: bold;
}
.entry.short {
  display: inline-flex;
  padding: 0 5px;
  white-space: nowrap;
  flex-flow: row nowrap;
  min-height: 21px;
}
.entry.short span:first-child {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
}
.entry .time {
  color: var(--font-color-darker-gray);
}
.lesson {
  position: absolute;
  width: 70%;
  left: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--smaller);
  color: var(--font-color-darker-gray);
  background: var(--color-primary-lightest);
  z-index: 8;
}</style>
