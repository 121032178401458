<script lang="ts">import { submenu } from '../../assets';
import Transition from '../transition/Transition.svelte';
import { clickOutside } from '../';
export let dropped = false;
export let size = 'big';
export let id;
</script>

<div
    class="wrapper"
    use:clickOutside
    class:dropped
    on:outclick="{() => (dropped = false)}"
    on:click
>
    <div class="buttonContent {size}" on:click="{() => (dropped = !dropped)}">
        <img src="{submenu}" alt="Submenu Icon" />
    </div>
    {#if dropped}
        <div class="content">
            <Transition fadeInOut style="z-index: 1000" duration="{200}">
                <slot />
            </Transition>
        </div>
    {/if}
</div>

<style lang="stylus">@font-face {
  font-family: "AnonymousPro";
  src: url("/vendor/Anonymous_Pro/AnonymousPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root.default-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 10px;
  --smaller: 12px;
  --small: 14px;
  --normal: 16px;
  --subheading: 18px;
  --lineHeight: 20px;
  --titleSmall: 22px;
  --titleLarge: 25px;
  --biggerLineHeight: 30px;
  --bigtitle: 45px;
}
:root.big-font {
  --font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  --super-small: 13px;
  --smaller: 15px;
  --small: 17px;
  --normal: 19px;
  --subheading: 21px;
  --lineHeight: 22px;
  --titleSmall: 25px;
  --titleLarge: 28px;
  --biggerLineHeight: 33px;
  --bigtitle: 48px;
}
.wrapper {
  position: relative;
}
.wrapper.dropped {
  background-color: var(--color-primary-lighter);
  border-radius: 5px;
}
.wrapper .buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.wrapper .buttonContent.big {
  width: 50px;
  height: 50px;
}
.wrapper .buttonContent.medium {
  width: 40px;
  height: 40px;
}
.wrapper .buttonContent.small {
  width: 30px;
  height: 30px;
}
.wrapper .content {
  position: absolute;
  right: 0;
  margin-top: 5px;
  z-index: 1000;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.05));
}</style>
