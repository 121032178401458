<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { goto } from '@roxi/routify';
import { Button, arrow_breadcrumbs } from '@stadtlandnetz/design';
import { InputType } from '@stadtlandnetz/forms';
import { Field as FormField } from '@stadtlandnetz/forms/components';
import { createEventDispatcher } from 'svelte';
import { _ } from 'svelte-i18n';
import { api } from '../../services/api.service';
import { clearData, loading } from '../../stores/api.stores';
import { bundID, error, sentTransportRequests, success } from '../../stores/data.stores';
import KeyupListener from '../KeyupListener.svelte';
export let dialog = false;
const dispatch = createEventDispatcher();
const confirmValue = $_(`account.${$sentTransportRequests.length > 0 ? 'deactivate' : 'delete'}.confirmValue`);
let password = '';
let confirmed;
$: confirmed = password.length > 0 && (!$bundID || password.toLowerCase() === confirmValue.toLowerCase());
function deleteOrDeactivateAccount() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            $loading = 'blocking';
            const response = yield api.users.deleteOrDeactivateAccount(password);
            if (response === null || response === void 0 ? void 0 : response.deletedOrDeactivated) {
                clearData();
                $goto('/');
                if ($sentTransportRequests && $sentTransportRequests.length > 0) {
                    success.set($_('account.deactivate.success'));
                }
                else
                    success.set($_('account.delete.success'));
            }
        }
        finally {
            $loading = undefined;
        }
    });
}
</script>

<KeyupListener key="Enter" condition={confirmed} callback={deleteOrDeactivateAccount} />

<div class="card">
    <div class="content">
        {#if $sentTransportRequests.length > 0}
            <span class="title">{$_('account.deactivate.title')}</span>
            <span class="text">{$_('account.deactivate.text', { values: { bundid: $bundID ? 'true' : 'false' } })}</span>
        {:else}
            <span class="title">{$_('account.delete.title')}</span>
            <span class="text">{$_('account.delete.text', { values: { bundid: $bundID ? 'true' : 'false' } })}</span>
            <span class="text warning">
                {$_('account.verification.modal.warningUndoAction')}
            </span>
        {/if}
        <div class="field">
            <FormField
                field={{
                    name: `confirmDelete`,
                    id: `confirmDelete`,
                    type: !$bundID ? InputType.PASSWORD : InputType.TEXT
                }}
                bind:value={password}
            />
        </div>
    </div>
    <div class="bottom">
        <div class="buttons-container">
            <Button
                text={$_('forms.cancel')}
                type="outline"
                bold={false}
                fullWidth={false}
                on:click={() => {
                    dialog = false
                    $goto('/account')
                }}
            />
            <Button
                type="danger"
                text={$_($sentTransportRequests.length > 0 ? 'account.deactivate.button' : 'account.delete.button')}
                fullWidth={false}
                disabled={!confirmed}
                on:click={deleteOrDeactivateAccount}
            />
        </div>
        {#if !$bundID}
            <div class="forgot-password-link">
                <img src={arrow_breadcrumbs} alt="Arrow Icon" />
                <span on:click={() => dispatch('forgot')}>
                    {$_('login.forgot')}
                </span>
            </div>
        {/if}
    </div>
</div>

<style lang="stylus">.card {
  width: 460px;
  overflow: hidden;
}
.card .content {
  padding: 54px 50px 0px 50px;
  display: grid;
}
.card .content .title {
  color: var(--black);
  font-size: var(--titleLarge);
  font-weight: 700;
  margin-bottom: 30px;
}
.card .content .field,
.card .content .text {
  margin-bottom: 20px;
}
.card .content .warning {
  font-weight: 700;
}
.card .bottom {
  background: var(--color-gray-background);
  padding: 20px 50px 30px;
}
.card .bottom .buttons-container {
  display: grid;
  grid-template-columns: 140px 140px;
  align-items: center;
  justify-content: space-between;
}
.card .bottom .forgot-password-link {
  display: flex;
  align-items: center;
  color: var(--font-color-darker-gray);
  text-decoration: underline;
  font-size: var(--smaller);
  cursor: pointer;
  margin-top: 15px;
}
.card .bottom .forgot-password-link span {
  margin-left: 1em;
}
@media screen and (max-width: 1100px) {
  .card {
    width: unset;
  }
  .card .bottom {
    display: grid;
    grid-template-rows: 2.5fr 1fr;
    padding: 30px 45px;
  }
  .card .bottom .buttons-container {
    grid-template-columns: none;
    align-items: unset;
    justify-content: unset;
  }
}
@media screen and (max-width: 800px) {
  .card .bottom .buttons-container {
    grid-gap: 20px;
  }
}</style>
